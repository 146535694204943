/*import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

//import { BrandsComponent } from './brands.component';
import { BrandscatlogComponent } from './brandscatlog.component';
import { CatlogModule } from '../catlog/catlog.module';
 @NgModule({
  declarations: [BrandscatlogComponent],
  exports:[ BrandscatlogComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    CatlogModule
  ],
  
})
export class BrandscatlogModule { } */




import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

//import { HomeComponent } from './home.component';
/* import {CategoriesComponent} from '../categories/categories.component'; */
//import { CatlogComponent } from './catlog.component';
import { BrandscatlogComponent } from './brandscatlog.component';

import { SectionsModule } from '../sections/sections.module';
import {CategoriesModule} from '../categories/categories.module';
import { SearchcategoryModule } from '../searchbycategory/searchcategory.module';
 import { OffersModule } from '../offers/offers.module';
import { BrandsModule } from '../brands/brands.module';
import { CatlogModule } from '../catlog/catlog.module';
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
         CategoriesModule ,
         SearchcategoryModule,
         OffersModule ,
         BrandsModule,
         CatlogModule
         
         
    ],
    declarations: [ BrandscatlogComponent ],
    exports:[ BrandscatlogComponent ],
    providers: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BrandscatlogModule { }
