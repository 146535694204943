import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class OffersService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getalloffers(){
    return this.http.get(`${this.baseurl}/get_all_data_api_final/offers`,{responseType:"json"})
  }
}
