import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

import { bannerapiService } from './bannerapi.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
import { ProductdetailService } from 'src/app/productdetail/productdetail.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    starRating = 4;
    banners;
    bannerurl;
baseurl;
    statename : {};
    cities : {};
    products : {};
    serachdata : [];
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    username ;
    previousData = '';
    codeValue :any;
    id;
    name;
    checkStatus: boolean;
    logout:boolean;
    count;
    toggle:boolean = true;

    loginstatus$ = new BehaviorSubject<boolean>(null);
    Username$ : Observable<string>;
    constructor(private api:bannerapiService, private auth:ProductdetailService, private auth1:LoginService, public location: Location, private router: Router) {
      this.auth.cartSubject.subscribe((data)=>{
        this.cartItem = data;
        //alert(data)
      })


      this.auth1.sessioncname.subscribe((data)=>{
        this.name = data;
      })
      this.counter();


    }
counter(){
     //new
     var customerid = sessionStorage.getItem('CustomerId');
     this.auth1.countCartDataByCustomerId(customerid).subscribe((data)=>{
       this.count =data["Data"];
      //alert(this.count)
      console.log("count"+this.count)
      this.cartNumberFunc();
      })
  }
  cartNumber :number =0;
  cartNumberFunc(){
    //alert("hello")
    var cartValue =JSON.parse(localStorage.getItem('localcart'));
    //this.cartNumber =cartValue.length;
    this.cartNumber = this.count;
    this.auth.cartSubject.next(this.cartNumber);
    console.log(this.cartNumber);
  }
    
  /* activateClass(subModule){
    alert("hello")
    subModule.active = !subModule.active;    
  } */

  change(){
    this.toggle = !this.toggle;
  }
    
    
    ngOnInit() {

      //piyush start for count 07/09/2021

      
      

      let customerid= sessionStorage.getItem('CustomerId');
   //  console.log(customerid)

    /*  this.api.countCartDataByCustomerId(customerid).subscribe((data)=>{
       this.count =data["Data"];
       alert(this.count)
       console.log("count"+this.count)
     }) */


      //piyush end

      /* this.LoginService.postuser(login).subscribe(
        res =>{ 
          this.loginstatus$.next(res["Data"])
        },); */
     
     // console.log("calling navbar");
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });

     /* this.api.getbannerdata().subscribe((bannersdata) =>{
            
        this.bannerurl ="http://autoparts.sikho99.com/Project/resources/images/";
this.baseurl="http://autoparts.sikho99.com";
        this.banners= bannersdata["Data"][0]["Banner"];
     //   this.data = response["Data"];
        console.log(bannersdata["Data"][0]["Banner"]);

        }); */


     /* this.api.getstatedata().subscribe((statedata)=>{

        this.statename = statedata["Data"];

     });  */  
     
     
      this.username= sessionStorage.getItem('CustomerName');
     console.log(this.username)

     
     

     //this.name = sessionStorage.getItem('CustomerName');
     //console.log(this.name)
      console.log("before  Name  "+this.name); 
      this.name = sessionStorage.getItem('CustomerId');
      // this.name = sessionStorage.getItem('CustomerName');
      this.checkStatus = this.localStorageItem();
      console.log("After name "+this.name);
      console.log("Check status"+this.checkStatus);

    
      this.cartItemFunc();

    }


    
    cartItem :number = 0; 
    cartItemFunc(){
     
      if(localStorage.getItem('localcart') != null){
        var cartCount =   JSON.parse(localStorage.getItem('localcart'));
        this.cartItem =cartCount.length;
      }
    }

    public localStorageItem(): boolean {
     
      console.log(this.name)
      if (this.name == null ) {
       //sessionStorage.clear();
//alert("login")
        return true;
      } else{
        //sessionStorage.clear();
       this.logout=true;
       //alert("logout")
       //indow.location.replace("/home");
        return false;
        
        
      };
    }

    refresh(): void {
      
      window.location.reload();
      
      //window.location.replace("/home");
  }
    clearall(){
      
      sessionStorage.clear();
      localStorage.clear();
      
      this.refresh();
      
      return false;
      

      //this.refresh();
    }
     /* onChangeState(ProductName: string) {
        //console.log(StateId);
        if (ProductName!=null) {
          this.api.searchdata(ProductName).subscribe(
            data => this.serachdata = data["Data"]
            
          );
        } else {
          this.serachdata = null;
        }
      } */ 

    /* onChangeState(StateId: number) {
        //console.log(StateId);
        if (StateId!=null) {
          this.api.getcitydata(StateId).subscribe(
            data => this.cities = data["Data"]
          );
        } else {
          this.cities = null;
        }
      } */

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    /* codeList = [
      { code: 'abcdhe568dhjkldn', name: 'item1' },
      { code: 'ksdkcs7238t8cds', name: 'item2' },
      { code: 'kascggibebbi', name: 'item3' }
      ]; */
      
       public saveCode(ProductName :string): void {
      //let name = e.target.value;
      if(ProductName.length===3){
        this.api.inputsearch(ProductName).subscribe(
          data => {
            this.serachdata = data["Data"];
            console.log(data["Data"]);
            this.id =data["Data"]['ProductId'];
            console.log(this.id);
          }
        );

        
      }
      console.log(this.serachdata);
      
     // console.log(this.serachdata);
      
      //let list = this.serachdata.filter(x => x.data === name)[0];
      //console.log(list.id);
      }
      cart() {
        this.router.navigate(['/cart']);
      }

      navsearch(){
        //console.log('name is :',this.codeValue);
        //alert(this.codeValue)
        this.router.navigate(['/catlog',this.codeValue]);
       // this.api.navsearch()
      }

  /* onKey(ProductName: string) { // without type info
   // this.values += event.target.value;
    if (ProductName!=null) {
      this.api.inputsearch(ProductName).subscribe(
        data => this.cities = data["Data"]
      );
    } else {
      this.cities = null;
    }

    //console.log(this.values);
  } */
}
