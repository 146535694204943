import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderdetailService } from '../orderdetail/orderdetail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../global-constant';
import Swal from 'sweetalert2';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-returnorder',
  templateUrl: './returnorder.component.html',
  styleUrls: ['./returnorder.component.css']
})
export class ReturnorderComponent implements OnInit {
  orderdetails;
  otherdeatils;
  bannerurl = Global.bannerurl;
  paymentfail;
  selectedItem: any;
  loading: boolean;
  orderid: any;
  rbtn = false;
  message: string;
  show = false;
  returnreasonform:FormGroup;
  closeResult: string;
  @ViewChild('returnreasonmodal', { static: true }) returnreasonmodal: TemplateRef<any>;
  constructor(private modalService: NgbModal,private api:OrderdetailService,private router:Router,private route: ActivatedRoute,private  formbuilder:FormBuilder) { }

  open(content) {
    this.modalService.open(content , {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    this.setFormState();
    this.selectedItem = new Array<string>();

    let  customerId;
    let URL;
    let URLElement;
    let URLElement3;

    URL=this.router.url.split('/');
    URLElement=URL[2];  
    URLElement3 = URL[3];
    this.orderid =URL[2];
    //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
      //this.checkstock();
      if (URLElement!=null) {
        this.api.getorderdetails(URLElement).subscribe(
          data => {
            this.orderdetails = data["Data"][0]["Orders"];
            this.otherdeatils = data["Data"][0]["OtherDetail"];
            console.log(this.otherdeatils);
      });
      } else {
        this.orderdetails = null;
      }

      /* if(URLElement3!=null){
        this.paymentfail = true;
      } */

  }

  getorderdetailid(e:any,OrderdetailId:string){
    // this.loading = true;
   // console.log(e)
   // alert(e)
     if(e.target.checked){
      // var customerid = sessionStorage.getItem('CustomerId');
       console.log(OrderdetailId +'checked');
       this.selectedItem.push(OrderdetailId);
       let all = this.selectedItem;
       this.loading =true;
       console.log(this.selectedItem)
     
     }
      else{
        console.log(OrderdetailId +'unchecked');
        var index = this.selectedItem.indexOf(OrderdetailId);
      this.selectedItem.splice(index,1);
      var customerid = sessionStorage.getItem('CustomerId');
      this.loading =true;
       console.log(this.selectedItem)
 
       
     } 
     
     
   }
   setFormState(): void{
    //console.log(this.defaddress)
    this.returnreasonform = this.formbuilder.group({
 
      reason : ['',[Validators.required]],
      description : ['',[Validators.required]],
    })
  }
  onSubmitreturnreason(){
    let reason =this.returnreasonform.value.reason;
    let description = this.returnreasonform.value.description;

    /* alert(reason)
    alert(description) */
    this.api.returnOrder(this.orderid,JSON.stringify(this.selectedItem),reason,description).subscribe((res)=>{
      let data = res["Data"];
      if(data == 1){
       // alert("return order sucessfully");
        this.showsweetalertreturn();
      }
    })
  }
   returnorder(){
     console.log(this.selectedItem)
     if(this.selectedItem.length == 0){
       //alert("hello")
       this.show =true;
       this.message = "please select atleast one product*";

     }
     else{
      this.show = false;
      this.open(this.returnreasonmodal);
     }
     /* else{
       
      this.api.returnOrder(this.orderid,JSON.stringify(this.selectedItem)).subscribe((res)=>{
        let data = res["Data"];
        if(data == 1){
         // alert("return order sucessfully");
          this.showsweetalertreturn();
        }
      })
 

     } */
     
   }

   showsweetalertreturn(){
    Swal.fire({
      title: 'Thanks',
      text: 'Your Return Request Sucessfully Send',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
        //this.router.navigate(['/myorder']);
        this.router.navigate(['/myorder']).then(() => {
          window.location.reload();
        });
     // this.refresh();
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }

}
