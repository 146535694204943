import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class UseapiService {

  constructor(private http:HttpClient) { }

  /* getapidata(){
    return this.http.get("https://suratjugaad.idnmserver.com/Admin/Ajax/getBannerData",{responseType:"json"})
  } */
}
