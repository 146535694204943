import { Component, OnInit ,ViewChild,AfterViewInit} from '@angular/core';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgForm} from '@angular/forms';
import { UserService } from './user.service';


import { Router } from '@angular/router';
import {Customer} from './user';
import { empty } from 'rxjs';
import Swal from 'sweetalert2';
import { Global } from '../global-constant';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    providers:[UserService]
})
export class SignupComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;
    focus2;
    ShowSuccessmessage : boolean;
    serverErrorMessage : string;

  signUpForm :FormGroup;
  message : string;
  Error =false;
  bannerurl = Global.bannerurl;
  banners: any;
  getotpform: FormGroup;
  closeResult = '';
  customerid;
  @ViewChild('otpmodal') otpmodal;
  verifyotp: any;
  mobileno: any;
  //isLoading = false;
 // button = 'Submit';
  /* ngAfterViewInit() {
    this.open(this.otpmodal);
  } */
    constructor(private modalService: NgbModal,private userService:UserService,private  formbuilder:FormBuilder,private router:Router) { }

    open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
    ngOnInit() {
      
    
      this.setFormStateotpverification();
      this.setFormState(); 
      
      this.userService.getbannerdata().subscribe((bannersdata) =>{
         this.banners= bannersdata["Data"][0]["Banner"];
         });
    }
    
    setFormState(): void{
        this.signUpForm = this.formbuilder.group({
           name : ['',[Validators.required]],
           //companyname : ['',[Validators.required]],
           mobileno : ['',[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
           //gstno : ['',[Validators.required]],
           //address : [''],
           //pincode : ['',[Validators.required]],
          password : ['',[Validators.required]],
          emailid : [''],
          privacy : [false,[Validators.requiredTrue]],
          //customerimage : ['',[Validators.required]],
          //customershopimage : ['',[Validators.required]]
        })
      }

      onFileChanged(event){
        //console.log(event.target.files[0])
        if(event.target.files.length > 0 ){
          const customerimage = event.target.files[0];
          
          this.signUpForm.get('customerimage').setValue(customerimage);


        }
      }
      onFileChanged1(event){
        //console.log(event.target.files[0])
        if(event.target.files.length > 0 ){
          const shopimage = event.target.files[0];
          this.signUpForm.get('customershopimage').setValue(shopimage);


        }
      }
      onSubmit(){
     //   this.isLoading = true;
//    this.button = 'Processing';
        //this.open(otpmodal);
        let login = this.signUpForm.value;
        this.login(login);
      }
      login(login : Customer){
        this.userService.postuser(login).subscribe(
          res =>{ 
            //debugger;
         //   this.isLoading = false;
        //  this.button = 'Submit';
            let Message=res["Message"];
            let condition=res["IsSuccess"];
            let data=res["Data"];
            

            //console.log(res);
           /* if(login.mobileno == data){
              console.log("Hii");
            }*/
            
            if(data == 1){
              //alert("i")
             Swal.fire('Oops...', 'Customer Already Registered Please Enter New Mobile Number :)', 'error')
              
            }
            else {
            //  alert("else")
            this.mobileno =res["Data"][0]["CustomerPhoneNo"];
              let otpcheck =res["OtpMessage"];
              if(otpcheck == "OTP Send Successfully"){
                //this.open();
                this.customerid =res["Data"][0]["CustomerId"];
                this.open(this.otpmodal);
              }
              /* Swal.fire({
                title: 'Thanks',
                text: 'Your Registration is Done',
                icon: 'success',
                //showCancelButton: true,
                confirmButtonText: 'Login',
                
              }) */
             // this.router.navigate(['/login']);
            }
            //var success = res;
            //console.log(data['length']);
            //console.log(data.length);
          //  console.log(res);
            
            //console.log(data[0]);
            //console.log(data[0]['CustomerName'])
            
            //let cname = data[0]['CustomerName']
           // alert(data)
  //          if(data){
              //this.signUpForm.reset(); 
              //this.ShowSuccessmessage = true;
              //setTimeout(() => this.ShowSuccessmessage = false ,3000);
              //Swal.fire('Hello world!')
              //localStorage.setItem("Employee",JSON.stringify(success));
              //this.router.navigate(['/login']);
             // sessionStorage.setItem('CustomerName',cname);
              //this.resetForm(form);
              //piyush start 24/07/2021
               
             /*  Swal.fire({
                title: 'Thanks',
                text: 'Your Registration is Done',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Login',
                
              }).then((data) => {
                
                // if (data.isConfirmed) {
                //   this.router.navigate(['/login']);
                // } 
                 if(data) 
                 {
                  Swal.fire(
                    'Cancelled',
                    'Customer Allready Registered Please Enter New Mobile Number :)',
                    'error'
                  )
                }
              }) */

    //          alert(data)
              /* if(data == 1){
                alert("i")
               Swal.fire('Oops...', 'Customer Already Registered Please Enter New Mobile Number :)', 'error')
                
              }
              else {
                alert("else")
                let otpcheck =res["OtpMessage"];
                if(otpcheck == "OTP Send Successfully"){
                  //this.open();
                  this.customerid =res["Data"][0]["CustomerId"];
                  this.open(this.otpmodal);
                }
                /* Swal.fire({
                  title: 'Thanks',
                  text: 'Your Registration is Done',
                  icon: 'success',
                  //showCancelButton: true,
                  confirmButtonText: 'Login',
                  
                }) 
                this.router.navigate(['/login']);
              }
              //piyush end
            } */
           /*  else {
              //alert("hello")
              this.Error =res["IsSuccess"];
              this.message =res["Message"];
              setTimeout(() => this.Error = false  ,6000);
            //  this.serverErrorMessage= true;
            }  */
    
    
              
              //console.log(res.valueOf.arguments.CustomerName);
              
          });
      
      }
      /* getprivacy(e){
        if(e.target.checked){
          alert("checked")
        }
        else{
          alert("un checked")
        }
      } */

    /* onSubmit(form:NgForm){
        this.userService.postuser(form.value).subscribe(
            res =>{
                this.ShowSuccessmessage = true;
                setTimeout(() => this.ShowSuccessmessage = false ,3000);
                this.resetForm(form);
            },);
        }
    resetForm(form:NgForm){
            this.userService.selecteduser ={
                name : '',
                companyname : '',
                mobileno : '',
                gstno : '',
                address : '',
                pincode : '',
                password : ''
                
            };
            form.resetForm();
            this.serverErrorMessage='';
    } */


    // sweetalert code
    /* Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    }) */

    //for otp 17/9/21
    setFormStateotpverification(): void{
      //console.log(this.defaddress)
      this.getotpform = this.formbuilder.group({
        otp : ['',[Validators.required,Validators.minLength(6)]],
        /* confirmnewpassword : ['',[Validators.required,]], */
        
      })
    }
    onsubmitotp(){
     // alert("hello")
      let otp =this.getotpform.value.otp;
     // alert(this.getotpform.value.otp);
      this.userService.verifyOTP(otp,this.customerid).subscribe((res)=>{
        this.verifyotp =res["Data"];
        if(this.verifyotp == 1){
          
          /* Swal.fire({
                  title: 'Thanks',
                  text: 'Your OTP verification is Sucess',
                  icon: 'success',
                  //showCancelButton: true,
                  confirmButtonText: 'Login',
                  
                })
                this.router.navigate(['/login']); */

                Swal.fire({
                  title: 'Thanks',
                  text: 'Your OTP verification is Sucess',
                  icon: 'success',
                  //showCloseButton:true,
                  showCloseButton:true,
                 // showCancelButton: true,
                  confirmButtonText: 'Login',
                  confirmButtonColor: '#FD8E01',
                  
                }).then((result) => {
                  if (result.isConfirmed) {
                  //  alert("hello")
                   /*  this.otpmodal.nativeElement.close();
                     this.getotpform.disable();
                   this.otpmodal.close();
                   this.otpmodal.dismiss();
                   
                   this.otpmodal.hide(); */
                  // this.reloadComponent();
                    /* this.router.navigate(['/login']);
                    this.refresh(); */

                    this.router.navigate(['/login'])
                    .then(() => {
                         window.location.reload();
                     });                    
                   
                  
                  } else if (result.dismiss === Swal.DismissReason.close) {
                    
                  }
                  
                })
          
        }
        else{
          Swal.fire('Oops...', 'OTP verification failed Try Again :)', 'error')
        }
      })


      /* this.submitted =true;
      // alert(this.changepassword.value.newpassword);
      if(this.changepassword.value.newpassword== this.changepassword.value.confirmnewpassword){
        let password = this.changepassword.value;
        this.changepass(password);
        return;
      }
      else{
        let password = this.changepassword.value;
       // this.changepass(password);
      } */
    }
    resendotpapi(){
      this.userService.reSendOTPUsingSms(this.mobileno,this.customerid).subscribe((res)=>{
        let resend = res["Data"];
        if(resend == 1){
          Swal.fire({
            title: 'Thanks',
            text: 'OTP resend Sucessfully',
            icon: 'success',
            //showCloseButton:true,
            showCloseButton:true,
           // showCancelButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#FD8E01',
            
          }).then((result) => {
            if (result.isConfirmed) {
              //this.router.navigate(['/login']);
            
            } else if (result.dismiss === Swal.DismissReason.close) {
              
            }
            
          })

        }
      })
    }

    refresh(): void {
      window.location.reload();
    }
    reloadComponent() {
      let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
      }
  
}

