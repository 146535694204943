import { Component, OnInit,Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from './profile.service';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from './profile';
import { Password } from './password';
import { Global } from '../global-constant';
import Swal from 'sweetalert2';
import { PlaceorderService } from '../placeorder/placeorder.service';
import { PlaceorderComponent } from '../placeorder/placeorder.component';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent extends PlaceorderComponent implements OnInit {

    closeResult = '';
  profiledetails;
  fname: any;
  address: any;
  mobno: any;
  updateprofile:FormGroup;
  ShowSuccessmessage: boolean;
  Error: any;
  message: any;
  password: any;
  companyname;
  gstno;
  gstcertificate;
  customerimage;
  shopimage;
  changepassword;
  flag;
  submitted:boolean =false;
  bannerurl = Global.bannerurl;
  email: any;
  alladdress: any;
  addName: any;
  addid: any;
  alladd: any;
  show: boolean;
  hide: boolean;
 // setFormStateaddaddress:FormGroup;

  /* @Input() send:string;
  childmsg="hello parent"; */
  constructor (public placeorderservice:PlaceorderService,public modalService: NgbModal,public api:ProfileService,public  formbuilder:FormBuilder,public router:Router) { 
    super(modalService,placeorderservice,formbuilder,router)
  }

  get f (){return this.changepassword.controls}
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnInit(): void {
    this.placeorderservice.datatest.next();
    
    this.setFormState();
    this.setFormStatechangepassword();
    var customerid = sessionStorage.getItem('CustomerId');

    this.api.getprofiledatabycid(customerid).subscribe((data)=>{
      this.profiledetails = data["Data"];
 
      this.fname = data["Data"][0]["CustomerName"];
      this.address = data["Data"][0]["CustomerAddress"];
      this.mobno = data["Data"][0]["CustomerPhoneNo"];
      this.companyname = data["Data"][0]["CustomerCompanyName"];
      this.email = data["Data"][0]["CustomerEmailId"];
      //alert(this.companyname)
      this.gstno =data["Data"][0]["CustomerGstNo"];
      //this.password = data["Data"][0]["CustomerPassword"];
      this.shopimage = data["Data"][0]["CustomerShopImage"];
      this.customerimage =data["Data"][0]["CustomerImage"];
      this.gstcertificate =data["Data"][0]["CustomerGstCertificateImage"];
      this.hide = true;
      if(this.gstcertificate == ''){
       // alert("hello")
        this.show = true;
        this.hide = false;
      }
    })


    //get address 03/08/2021
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getAddress(customerid).subscribe((data)=>{
      this.alladdress = data["Data"];
      console.log(this.alladdress);
      //this.cname = data["Data"][0]["AddressForName"];
      for(let i=0;i<this.alladdress.length;i++){
       // this.cname = data["Data"][i]["AddressForName"];
        this.addName = data["Data"][i]["AddressName"];
        this.addid = data["Data"][i]["AddressId"];
       // this.changedefaid = data["Data"][i]["AddressId"];
        //this.alladd[i]=("asd"=>"asd" );
        //this.cname;
        //this.alladd[i]["add"]=this.addName;
        
        
       //  this.alladd.push(this.addid);
       
      
      }
    


     
    })
    /* alert("hello"+this.send) */

  }
 

  setFormState(): void{
    //console.log(this.defaddress)
    this.updateprofile = this.formbuilder.group({
      name : [this.fname,[Validators.required]],
      companyname : [this.companyname,[Validators.required]],
      //address : [this.address,[Validators.required]],
      //password : [this.password,[Validators.required]],
     // city : [this.city,[Validators.required]],
      //pincode : [this.pincode,[Validators.required]],
      mobileno : [this.mobno,[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      gstno :[this.gstno,[Validators.required]],
      CustomerImage:[this.customerimage,[Validators.required]],
      CustomerShopImage : [this.shopimage,[Validators.required]],
      CustomerGstCertificateImage : [this.gstcertificate,[Validators.required]],
      email : [this.email,[Validators.required]]
    })
  }

  onFileChangedshop(event){
    //console.log(event.target.files[0])
    if(event.target.files.length > 0 ){
      const CustomerShopImage = event.target.files[0];
      //console.log("ci"+shopimage)
      this.updateprofile.get('CustomerShopImage').setValue(CustomerShopImage);
    }
  }
  onFileChangedgst(event){
    //console.log(event.target.files[0])
    if(event.target.files.length > 0 ){
      const CustomerGstCertificateImage = event.target.files[0];
      this.updateprofile.get('CustomerGstCertificateImage').setValue(CustomerGstCertificateImage);
    }
  }
  onFileChangedcustomer(event){
    //console.log(event.target.files[0])
    if(event.target.files.length > 0 ){
      const CustomerImage = event.target.files[0];
      this.updateprofile.get('CustomerImage').setValue(CustomerImage);
    }
  }

 /*  onSubmit(updateprofileform:any){
    //console.log(updateprofileform)
    var fullname = updateprofileform.controls.fullname.value;

  } */
  onSubmit(){
    let login = this.updateprofile.value;
    this.login(login);
  }
  login(login : Customer){
    var customerid = sessionStorage.getItem('CustomerId');
    this.api.postuser(customerid,login).subscribe(
      res =>{ 
            let data=res["Data"];   
            console.log(data)
            if(data==1){
            //  alert("hello")
              //this.reloadComponent();
              this.showsweetalert();
            // this.refresh();
              //alert("Sucess")
             // this.updateprofile.reset(); 
             // this.ShowSuccessmessage = true;
             // setTimeout(() => this.ShowSuccessmessage = false ,3000);
              //localStorage.setItem("Employee",JSON.stringify(success));
              //this.router.navigate(['/login']);
             // sessionStorage.setItem('CustomerName',cname);
              //this.resetForm(form);
            }
            else{
              this.Error =res["IsSuccess"];
              this.message =res["Message"];
              setTimeout(() => this.Error = false  ,6000);

              
            
            //  this.serverErrorMessage= true;
            } 
      });
    }
    reloadComponent() {
      let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
      }
      refresh(): void {
        window.location.reload();
    }
    showsweetalert(){
      Swal.fire({
        title: 'Thanks',
        text: 'Profile Update Sucessfully ',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FD8E01',
        
      }).then((result) => {
        if (result.isConfirmed) {
         // this.router.navigate(['/cart']);
        this.refresh();
        } else if (result.dismiss === Swal.DismissReason.close) {
          
        }
        
      })
    }

    setFormStatechangepassword(): void{
      //console.log(this.defaddress)
      this.changepassword = this.formbuilder.group({
        newpassword : ['',[Validators.required,Validators.minLength(6)]],
        confirmnewpassword : ['',[Validators.required,]],
        
      },
      {
        validators :this.MustMatch('newpassword','confirmnewpassword')
      })
    }
    MustMatch(controlName:string,matchcontrolname:string){
      return(formGroup:FormGroup)=>{
        const control =formGroup.controls[controlName];
        const matchcontrol =formGroup.controls[matchcontrolname];
         if(matchcontrol.errors && !matchcontrol.errors.MustMatch){
        //  alert("1")
           return
         }
         if(control.value !== matchcontrol.value){
         // alert("2")
           matchcontrol.setErrors({MustMatch:true});
           return

         }
         else{
         //  alert("3")
           matchcontrol.setErrors(null);
           return

         }
      }
    }

    onclickchangepassword(){
      /* (formGroup:FormGroup)=>{
        const control =formGroup.controls[controlName];
        const matchcontrol =formGroup.controls[matchcontrolname];
      }; */

     /*  const control =changepassword.controls[controlName];
      const matchcontrol =changepassword.controls[matchcontrolname]; */
      this.submitted =true;

      
      // alert(this.changepassword.value.newpassword);
      if(this.changepassword.value.newpassword== this.changepassword.value.confirmnewpassword && this.changepassword.value.newpassword!=''){
        let password = this.changepassword.value;
        this.changepass(password);
        return;
      }
      else{
        let password = this.changepassword.value;
       // this.changepass(password);
      }
      
     // alert(password)
      //console.log(password);
      
      //console.log(this.changepassword)
     // this.changepassword.changepassword.va
     

    }
    changepass(password : Password){
      var customerid = sessionStorage.getItem('CustomerId');
      this.api.changepassword(customerid,password).subscribe(
        res =>{ 
              let data=res["Data"];   
              console.log(data)
              if(data){
                this.showsweetalert2();
              }
              else{
                this.Error =res["IsSuccess"];
                this.message =res["Message"];
                setTimeout(() => this.Error = false  ,6000);
  
                
              
              //  this.serverErrorMessage= true;
              } 
        });
      }
      showsweetalert2(){
        Swal.fire({
          title: 'Thanks',
          text: 'Password Change Successfully',
          icon: 'success',
          //showCloseButton:true,
          showCloseButton:true,
         // showCancelButton: true,
          confirmButtonText: 'Ok',
          confirmButtonColor: '#FD8E01',
          
        }).then((result) => {
          if (result.isConfirmed) {
           // this.router.navigate(['/cart']);
          this.refresh();
          } else if (result.dismiss === Swal.DismissReason.close) {
            
          }
          
        })
      }

}


/* let obj = new PlaceorderComponent(modalService,placeorderservice,formbuilder,router); */
