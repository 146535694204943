import { Component, OnInit,Input } from '@angular/core';
import { CategoryService } from './category.service';
import { Global } from '../global-constant';
import { Router } from '@angular/router';
import { URL } from 'url';
@Component({
  selector: 'app-searchbycategory',
  templateUrl: './searchbycategory.component.html',
  styleUrls: ['./searchbycategory.component.css'],
  
})
export class SearchbycategoryComponent implements OnInit {

  bannerurl = Global.bannerurl;
  categorys :{};
  view:boolean;
  hide:boolean;
  msg:boolean;
  @Input() data;
  URL;
  URLElement;
  mainModel;
  URLElement2;
  constructor(private api:CategoryService,private router:Router) { }

  ngOnInit(): void {
    this.URL=this.router.url.split('/');
    this.URLElement=this.URL[2]; 
   // this.URLElement2 =this.URL[3];
    console.log("URL Element"+this.URLElement);
  //alert(this.URLElement);
  if(this.URLElement !=null){
    setTimeout(() => {
      console.log('hide');
      //this.showElement = false;
      console.log(this.data);
     // this.api.getcategorydatavehicle(this.data,this.URLElement).subscribe((categorydata)=>{
      this.api.getcategorydatavehicle(this.data).subscribe((categorydata)=>{
        this.categorys = categorydata["Data"];
        this.view =true;
        this.hide =false;
        this.mainModel=this.URL[2]; 
        this.URLElement2 =this.URL[3];
        console.log(this.mainModel);
        //console.log(categorydata["Data"])
        if(this.categorys == ''){
          this.view = false;
          this.msg = true;
          

        }
    })
    }, 1000);

  }
  else{
    this.api.getcategorydata().subscribe((categorydata)=>{
      this.categorys = categorydata["Data"];
      this.view =true;
      this.hide =false;
      //console.log(categorydata["Data"])
  })
  }

  
  



  }
  testing(){
    this.api.getcategorydatavehicle(this.data).subscribe((categorydata)=>{
      this.categorys = categorydata["Data"];
      this.view =true;
      this.hide =false;
     // this.mainModel=this.URLElement;
      //console.log(categorydata["Data"])
  })

  }
  
  allcategory(){
    //alert(this.data)
    if(this.URLElement !=null){
      this.api.getcategorydataallvehicle(this.data).subscribe((categorydata)=>{
        this.categorys = categorydata["Data"];

        this.view =true;
        this.hide =false;
        this.mainModel=this.URLElement;

        
    })
    }
    else{
      this.api.allCategorys().subscribe((data)=>{
        this.categorys = data["Data"];
        this.hide =true;
        this.view =false;
    
    })
    }
    

  }
}
