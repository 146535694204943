import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  //baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getcategory(){
   // var Subcategory: any = new FormData();
   // Subcategory.append("SubcategoryId", SubcategoryId);
   return this.http.get(`${this.baseurl}/getAllCategoryData`,{responseType:"json"})
  }


}
