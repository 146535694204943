import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import {CategoriesComponent} from './categories.component';

@NgModule({
    
    imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    
    ],
    declarations: [ CategoriesComponent ],
    exports:[ CategoriesComponent ],
    
})
export class CategoriesModule { }