import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

//import { HomeComponent } from './home.component';
import { VehicleComponent } from './vehicle.component';
/* import {CategoriesComponent} from '../categories/categories.component'; */

import { SectionsModule } from '../sections/sections.module';
import { SearchcategoryModule } from '../searchbycategory/searchcategory.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
        SearchcategoryModule
         
         
    ],
    declarations: [ VehicleComponent ],
    exports:[ VehicleComponent ],
    providers: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class VehicleModule { }
