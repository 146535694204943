import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductdetailService {

 // baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
 baseurl =Global.baseurl;
 cartSubject = new Subject<any>();

  constructor(private http:HttpClient) { }

  getProductDetailByProductId(ProductId : number){
    var product: any = new FormData();
    product.append("ProductId", ProductId);
    return this.http.post(`${this.baseurl}/getProductDetailByProductId`,product)
  }

  getpartByPartId(PartId : number){

    return this.http.get(`${this.baseurl}/get_a_data_join_api_final/part/`+PartId)
  }

 

  addtocartByProductDeatil(CustomerId: string,PartId:number,CartQuantity:number){

    var addtocart: any = new FormData();
    addtocart.append("CustomerId", CustomerId);
    addtocart.append("PartId", PartId);
    addtocart.append("CartQuantity", CartQuantity);

    return this.http.post(`${this.baseurl}/addToCartOld`,addtocart);

  }

  lateststockwithcartdata(PartId:string,CustomerId: string){

    var stock: any = new FormData();
    stock.append("PartId", PartId);
    stock.append("CustomerId", CustomerId);
    
    //addtocart.append("CartQuantity", CartQuantity);

    return this.http.post(`${this.baseurl}/getProductDetailDataByLatestStockWithCartData`,stock);

  }
  
}
