import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class OrderdetailService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getorderdetails(OrderId : number){
    var orderdetail: any = new FormData();
    orderdetail.append("OrderId", OrderId);
    return this.http.post(`${this.baseurl}/orderHistoryDetail`,orderdetail)
  }


  returnOrder(OrderId : string,OrderDetailId :any,SalesreturnReason :string,SalesreturnDescription:string){
    var orderdetail: any = new FormData();
    orderdetail.append("OrderId", OrderId);
    orderdetail.append("OrderDetailId", OrderDetailId);
    orderdetail.append("SalesreturnReason", SalesreturnReason);
    orderdetail.append("SalesreturnDescription", SalesreturnDescription);

    return this.http.post(`${this.baseurl}/returnOrder`,orderdetail)
  }
}
