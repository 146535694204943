import { Component, OnInit } from '@angular/core';
import { OrderdetailService } from './orderdetail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../global-constant';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.css']
})
export class OrderdetailComponent implements OnInit {

  orderdetails;
  otherdeatils;
  bannerurl = Global.bannerurl;
  paymentfail;
  ReturnData: any;
  SalesreturnReason: any;
  ReturnStatus: any;
  constructor(private api:OrderdetailService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {

    let  customerId;
     /*  this.route.queryParams.subscribe(params =>{
       customerId = params["customerId"];
      })
      if(customerId!=null){
        //alert(customerId)
        sessionStorage.setItem('CustomerId',customerId);
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });



      } */

    let URL;
    let URLElement;
    let URLElement3;

    URL=this.router.url.split('/');
    URLElement=URL[2];  
    URLElement3 = URL[3];
    //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
      //this.checkstock();
      if (URLElement!=null) {
        this.api.getorderdetails(URLElement).subscribe(
          data => {
            this.orderdetails = data["Data"][0]["Orders"];
            this.otherdeatils = data["Data"][0]["OtherDetail"];
            this.ReturnData = data["Data"][0]["ReturnData"];
            this.SalesreturnReason = data["Data"][0]["ReturnData"][0]["SalesreturnReason"];
            this.ReturnStatus = data["Data"][0]["ReturnData"][0]["ReturnStatus"];
           // this.orderdeatilsstatus = data["Data"][0]["OrderdetailStatus"];
           // console.log(this.otherdeatils);
      });
      } else {
        this.orderdetails = null;
      }

      if(URLElement3!=null){
        this.paymentfail = true;
      }

  }

}
