import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global-constant';
@Injectable({
  providedIn: 'root'
})
export class bannerapiService {

  //baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getbannerdata(){
    return this.http.get(`${this.baseurl}/getBannerData`,{responseType:"json"})
  }
 /*  searchdata(ProductName :string)
  {
    return this.http.get(`${this.bannerbaseurl}/searchNew`,{responseType:"json"})
  } */

  inputsearch(ProductName : string){

    var serachdata: any = new FormData();
    serachdata.append("ProductName", ProductName);
    return this.http.get(`${this.baseurl}/searchNew`,serachdata)
  }

  countCartDataByCustomerId (CustomerId : string){

    var serachdata: any = new FormData();
    serachdata.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/countCartDataByCustomerId`,serachdata)
  }

  //piyush  for search 10/8/2021
  

  /* gettrendproductdata(){
    return this.http.get(`${this.bannerbaseurl}/getTrendingProducts`,{responseType:"json"})
  } */
  
  /* getstatedata(){
    return this.http.get("https://martncartnewapp.idnmserver.com/Admin/Ajax/getState",{responseType:"json"})

  }
  getcitydata(StateId: number){

    var formData: any = new FormData();
    formData.append("StateId", StateId);
    return this.http.post("https://martncartnewapp.idnmserver.com/Admin/Ajax/getCity",formData)

  } */


}
