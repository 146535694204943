import { Component, OnInit } from '@angular/core';
import { Router,NavigationExtras,NavigationEnd } from '@angular/router';
import { MyorderService } from '../myorder/myorder.service';
@Component({
  selector: 'app-billprint',
  templateUrl: './billprint.component.html',
  styleUrls: ['./billprint.component.css']
})
export class BillprintComponent implements OnInit {
  bill: any;

  constructor(private router:Router,private api:MyorderService) { }

  ngOnInit(): void {
    let URL;
    let URLElement;
    URL=this.router.url.split('/');
    URLElement=URL[2]; 

   // alert(URLElement)

    this.api.printBillWebsite(URLElement).subscribe((res:(any))=>{
      this.bill = res["Data"];
      console.log(res)
    })

    window.onafterprint = function() {
     /*  alert("hello") */
      window.location.reload();
  };
  }
 /*  onsubmitprint(){
    window.print();
  } */

  onsubmitprint(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

    
}



}
