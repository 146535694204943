import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class bannerapiService {
  
  //baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getbannerdata(){
    return this.http.get(`${this.baseurl}/getBannerData`,{responseType:"json"})
  }

  getformdata(){
    return this.http.get(`${this.baseurl}/getAllCategoryData`,{responseType:"json"})
  }

  getsubcategorydata(CategoryId: number){

    var subcategory: any = new FormData();
    subcategory.append("CategoryId", CategoryId);
    return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  getcarmakerstypedata(){
    return this.http.get(`${this.baseurl}/get_all_data_api_final/makerstype`,{responseType:"json"})
  }
  //old
 /*  getmakersdata(MakerstypeId:number){
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/makers/makerstype/`+MakerstypeId)
  } */
  //new
  getmakersdata(MakerstypeId:number){
    return this.http.get(`${this.baseurl}/getActiveMaker/`+MakerstypeId)
  }
 /*  getmodeldata(makersid: number){
   // var model: any = new FormData();
    //model.append("CategoryId", carmakersid);
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/model/makers/`+makersid)
  } */
  //new 22/09/2021
  getmodeldata(MakersId: number){
     var model: any = new FormData();
     model.append("MakersId", MakersId);
     return this.http.post(`${this.baseurl}/getModelDataByMakerIdWithAvailablePartData`,model)
   }
  getmodificationdata(modelid:number){
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/modification/model/`+modelid)
  }

  getCustomerDataById(CustomerId: number){

    var subcategory: any = new FormData();
    subcategory.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCustomerDataById`,subcategory)

  }


  
 
  


  
  

}
