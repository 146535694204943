import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
import { Observable } from 'rxjs';
import { Customer } from './profile';
import { Password } from './password';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getprofiledatabycid(CustomerId: string){

    var getprofile: any = new FormData();
    getprofile.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getProfileByCustomerId`,getprofile);

  }

  postuser(CustomerId :string, user :Customer):Observable<Customer>{
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerId", CustomerId);
     userData.append("CustomerName", user.name);
     userData.append("CustomerCompanyName", user.companyname);
     userData.append("CustomerPhoneNo", user.mobileno);
     userData.append("CustomerGstNo", user.gstno);
     userData.append("CustomerEmailId", user.email);
     //userData.append("CustomerPassword", user.password);
     //userData.append("CustomerGstNo", '');
     //userData.append("CustomerAddress", user.address);
    //userData.append("CustomerPincode", '');
     userData.append("CustomerImage", user.CustomerImage);
     userData.append("CustomerShopImage", user.CustomerShopImage);
     userData.append("CustomerGstCertificateImage", user.CustomerGstCertificateImage);
     

    return this.http.post<Customer>(`${this.baseurl}/updateProfile`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  changepassword(CustomerId :string, user :Password):Observable<Customer>{
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerId", CustomerId);
     userData.append("CustomerPassword", user.newpassword);
     
     

    return this.http.post<Customer>(`${this.baseurl}/updatePassword`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  
}
