import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient ,HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { CatlogService } from './catlog.service';
import { Router,NavigationExtras,NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../global-constant';
import { ProductdetailComponent } from '../productdetail/productdetail.component';
import { ProductdetailService } from '../productdetail/productdetail.service';
import Swal from 'sweetalert2';
import { bannerapiService } from '../home/bannerapi.service';
import noUiSlider from "nouislider";
import { max } from 'moment';
import { element } from 'protractor';
import { VehicleService } from '../vehicle/vehicle.service';
import { VehicleComponent } from '../vehicle/vehicle.component';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-catlog',
  templateUrl: './catlog.component.html',
  /* template :` './catlog.component.html',
  <div>
  <app-productdetail #one> </app-productdetail>
  </div>`, */
  styleUrls: ['./catlog.component.css']
})
export class CatlogComponent implements OnInit,AfterViewInit {


  sliderValue = 0;

  button;
  
  cproducts : {};
  bannerurl = Global.bannerurl;
 subcategoryids : {};
  loading;
  parts;
  bparts:{};
  brands: any;
  selectedItem :any;
  categorys: any;
  subcategorys: any;
  allcategorys : {};
  pdetails;
  iscart;
  atcshow: boolean;
   uparid ;
  cartpartid: any;
  allparid: any;
  show:boolean;

  carmakers:{};
  models:{};
  modifications:{};
  mtypes:{};
  makers:{};
  categorynamen: any;
  minprice;
  maxprice;
  customerid: string;
  atc1;
  a;
  aic;
  bid;
  getfullurl;
  cat_id;
  brand_id;
  public atc:any =  `ADD TO CART`;
  modification3: any;
  modification5: any;
  Sub: any;
  json_brand;
  
  @ViewChild(VehicleComponent) child;
  modification3url: any;
  modification5url: any;
  formdata: any;
  makerstypename: any;
  makersname: any;
  year: any;
  modelname: any;
  modificationname: any;
  isShown: boolean;
  totallength:any;
  page:number =1;
  sortselect;
  mylowvalue: number;
  myhighvalue: number;
  pagenf: boolean;
  count: any;
  categoryid_sub: any;
  makerstypename1: number;
  year1: any;
  modification1: any;
  makersname1: any;
  modelname1: any;
  breadcrumbshow3:boolean = false;
  breadcrumbshow5:boolean =false;
  homepagebreadcrumb5: boolean;
  modelid: any;
  ModificationId: any;
  ModelId: any;
  homepagebreadcrumb3: boolean;
  model3: any;
  maxrange: any;
  constructor(private loginservice:LoginService,private vehicleservice:VehicleService, private api:CatlogService,private api1:ProductdetailService,private homeapi:bannerapiService, private router:Router,private route: ActivatedRoute,location: Location) { }

  

  ngAfterViewInit(){
 /*    var slider = document.getElementById("input-slider");

    noUiSlider.create(slider, {
      start: 40,
      connect: [true, false],
      range: {
        min: 0,
        max: 100
      }
    }); */
    

    /* var slider2 = document.getElementById("input-slider-range");

    noUiSlider.create(slider2, {
      start: [0, 50000],
      connect: true,
      range: {
        min: 0 ,
        max: 50000
      }
    }); */

    /* alert(this.child.piyush)
    console.log(this.child.piyush) */
  }

  
  minValue: number = 0;
  maxValue: number = 50000;
  options: Options = {
    floor: 0,
    ceil: 50000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
        
           this.mylowvalue = value;
       //   console.log(mylowvalue)
          return "" + value;

        case LabelType.High:

          this.myhighvalue = value;
       //   console.log(myhighvalue)
          return "" + value;
        
        default:
          return "" + value;
      }
    }
  };

  


  ngOnInit(): void {   
    this.api.getDynamicMaxRange().subscribe((res)=>{
      this.maxrange = res["Data"];
    //  alert(this.maxrange)
    //alert(Options['ceil'])
    console.log(Options)
    })

    /* const dictionary = {
      'key1': 'value1',
      'key2': 'value2'
    };
    
    console.log(dictionary['key1']);
    alert(dictionary['key1']) */
 // alert(LabelType.Low)
 //   this.loading =true;
    if (window.screen.width <= 360) { // 768px portrait
      this.isShown = false;
    }
    else{
      this.isShown = true;
    }
    this.vehicleservice.vehiclepagedata.subscribe((res)=>{
      URLElement2 =res;
      alert(URLElement2)
    })
    /* this.route.queryParamMap.subscribe((params: any) => console.log(params)); */
     this.route.queryParamMap.subscribe((params:any) => this.bid = (params.params.BrandId));
     //alert(this.bid)
    this.customerid = sessionStorage.getItem('CustomerId');
    this.homeapi.getcarmakerstypedata().subscribe((data)=>{
      this.mtypes = data["Data"];
    })

    this.selectedItem = new Array<string>();
    let URL;
    let URLElement;
    let URLElement2;
    let BrandElement;
    URL=this.router.url.split('/');
    URLElement=URL[2]; 
    let searchurl = URL[2]; 
    BrandElement =URL[2];
    URLElement2=URL[3];
    console.log("URL Element"+URLElement);
    console.log("URL Element 2"+URLElement2);

    let category_id : string;
    let brand_id;
    if(searchurl !=null ){
    //  alert(URLElement)
    }
    if(URLElement !=null){
    //  alert(URLElement)
      let navigate = 'Home >'+this.categorynamen;
    }
    this.route.queryParams.subscribe(params =>{
      category_id = params["cat_id"];
      this.cat_id = params["Cat"];
    })
    console.log(category_id)

    this.route.queryParams.subscribe(params =>{
      brand_id = params["BrandId"];
      this.brand_id = params["BrandId"];

    })
    //for model wise filter 20/09/2021
    this.route.queryParams.subscribe(params =>{
     this.modification3 = params["modification3"];
    })
    this.route.queryParams.subscribe(params =>{
      this.modification5 = params["modification5"];
      this.minprice =params["MinPrice"];
     })
     if(this.cat_id !=null && this.modification3!=null || this.modification5 !=null){
      this.loading =true;
     // alert("hello")
      //old
      /* var customerid = sessionStorage.getItem('CustomerId');
      var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Cat='+this.cat_id;
      this.getfullurl = encodeURI('/catlog'+stringURL);
      // location.pathname();
      //  alert(this.getfullurl)
      // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
      this.parts = data["Data"];
      // this.iscart = data["Data"][0]["isCart"]
         // console.log(data["Data"][0]["isCart"]);
          for(let i=0;i<this.parts.length;i++){
            if(data["Data"][i]["isCart"] ==true){
              this.parts[i]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else if(data["Data"][i]["isCart"] ==false){
             // this.atcshow = true;
             this.parts[i]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          }
      })  */
      this.filterapiv2();

      /* this.api.getModificationDataByModelIdOrModificationId(this.modification3,this.modification5).subscribe((res)=>{
        this.formdata =res["Data"];
        this.makerstypename =res["Data"][0]["MakerstypeName"];
        this.makersname =res["Data"][0]["MakersName"];
        this.modelname =res["Data"][0]["ModelName"];
        this.year =res["Data"][0]["ModificationStartYear"];
        this.modificationname =res["Data"][0]["ModificationName"];

        console.log(this.makersname)
        this.homepagebreadcrumb5 = true;

      }) */
      this.api.getModificationDataByModelIdOrModificationId(this.modification3,this.modification5).subscribe((res)=>{
        this.formdata =res["Data"];
        this.makerstypename =res["Data"][0]["MakerstypeName"];
        this.makersname =res["Data"][0]["MakersName"];
        this.modelname =res["Data"][0]["ModelName"];
        this.year =res["Data"][0]["ModificationStartYear"];
        this.modificationname =res["Data"][0]["ModificationName"];
        this.model3 = res["model3"];
        //alert(this.model3)
        console.log(this.makersname)
        this.homepagebreadcrumb5 = true;
        
      //  localStorage.getItem('breadcrumb',this.makerstypename)
      //  alert(this.year)
        if(this.model3 ==1){
          //alert("hello")
       // this.year = '';
          
       // this.model3 = '';
        
        this.homepagebreadcrumb3 = true;    
        this.homepagebreadcrumb5 = false;    
        }
        if(this.year !=null){
         // alert("year")
          this.homepagebreadcrumb5 = true;
          this.homepagebreadcrumb3 = false;
        }
        
        localStorage.setItem('1',this.makerstypename);
      localStorage.setItem('2',this.makersname);
      localStorage.setItem('3',this.modelname);
      localStorage.setItem('4',this.year);
      localStorage.setItem('5',this.modificationname);

        
      })

     }
    //filter end 20/09/2021
    //console.log(category_id)
    //alert("Brand id :"+brand_id)
      //console.log( localStorage.getItem('subcategoryid'));
    //console.log(URLElement);
    //when querystring params 
    //this.checkstock();
    //offer api 14/09/2021
    let offercode;
    this.route.queryParams.subscribe(params =>{
      offercode = params["offer"];
    })
    if(offercode !=null){
      //alert(offercode)
      var customerid = sessionStorage.getItem('CustomerId');
      this.api.getProductByOfferCode(customerid,offercode).subscribe(
        data => { this.parts = data["Data"];
       // alert("hello")
        console.log("Show data by offercode only url querystring params");
        //this.getpartdatawithiscart();
        for(let i=0;i<this.parts.length;i++){
          if(data["Data"][i]["isCart"] ==true){
            //alert("hello")
            this.parts[i]["atcshow"] = false;
            console.log("Product in already cart")
          }
          else if(data["Data"][i]["isCart"] ==false){
            //alert("False")
           // this.atcshow = true;
           this.parts[i]["atcshow"] = true;
            console.log("Product in not already cart ")
          } 
        }
        this.categorynamen = data["Data"][0]["CategoryName"];
        });

    }
    //offer end
    //serach by brandid using query string param 13/08/2021
    if(this.brand_id!=null && this.modification3==null && this.modification5 ==null && this.minprice ==null){
      this.loading =true;
      //alert("hello")
    //alert("hello")
   //   alert("Brand id :"+this.brand_id)
      /* var customerid = sessionStorage.getItem('CustomerId');
      this.api.getPartDataByBrandId(customerid,brand_id).subscribe(
        data => { this.parts = data["Data"];
       // alert("hello")
        console.log("Show data by brand only url querystring params");
        //this.getpartdatawithiscart();
        for(let i=0;i<this.parts.length;i++){
          if(data["Data"][i]["isCart"] ==true){
            //alert("hello")
            this.parts[i]["atcshow"] = false;
            console.log("Product in already cart")
          }
          else if(data["Data"][i]["isCart"] ==false){
            //alert("False")
           // this.atcshow = true;
           this.parts[i]["atcshow"] = true;
            console.log("Product in not already cart ")
          } 
        }
        this.categorynamen = data["Data"][0]["CategoryName"];
        }); */
        //new 14/09/2021
        //alert(this.brand_id);
        //this.json_brand="";
          this.selectedItem.push(this.brand_id);
         // this.json_brand=JSON.stringify(this.selectedItem);
        //}
        
        /* this.router.navigate(['/catlog'], { queryParams: { 'CustomerId':customerid,'BrandId':this.brand_id } }); */
        this.router.navigate(['/catlog'], { queryParams: { BrandId: JSON.stringify(this.selectedItem)} });
        
        //old
      //   var customerid = sessionStorage.getItem('CustomerId');
      //   var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&CustomerId='+customerid;
      //  /*  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&CustomerId='+customerid +'&BrandId='+this.brand_id; */
      // this.getfullurl = encodeURI('/catlog'+stringURL);
      // // location.pathname();
      // //  alert(this.getfullurl)
      // // alert(a)
      
      // this.api.filterv2(this.getfullurl).subscribe((data)=>{
      // this.parts = data["Data"];
      // // this.iscart = data["Data"][0]["isCart"]
      //    // console.log(data["Data"][0]["isCart"]);
      //     for(let i=0;i<this.parts.length;i++){
      //       if(data["Data"][i]["isCart"] ==true){
      //         this.parts[i]["atcshow"] = false;
      //         console.log("Product in already cart")
      //       }
      //       else if(data["Data"][i]["isCart"] ==false){
      //        // this.atcshow = true;
      //        this.parts[i]["atcshow"] = true;
      //         console.log("Product in not already cart ")
      //       } 
      //     }
      // }) 

      this.filterapiv2();

    }
    
    //for only category wise data in filter api 
    if(this.cat_id!=null && this.modification3==null && this.modification5 ==null){
      this.loading =true;
      /* var customerid = sessionStorage.getItem('CustomerId');
      this.api.getPartDataByCategoryIdWithCartData(customerid,category_id).subscribe(
        data => { this.parts = data["Data"];
        console.log("Show data by category only url querystring params");
        //this.getpartdatawithiscart();
        

        for(let i=0;i<this.parts.length;i++){
          if(data["Data"][i]["isCart"] ==true){
            //alert("hello")
            this.parts[i]["atcshow"] = false;
            console.log("Product in already cart")
          }
          else if(data["Data"][i]["isCart"] ==false){
           // this.atcshow = true;
           this.parts[i]["atcshow"] = true;
            console.log("Product in not already cart ")
          } 
        } 

this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub } });
   
        });*/
        //new by filter 14/09/2021
       // alert("hell")
        this.router.navigate(['/catlog'], { queryParams: { 'CustomerId':customerid,'Cat':this.cat_id } });
        //old
       /*  var customerid = sessionStorage.getItem('CustomerId');

  
        var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&CustomerId='+customerid +'&Cat='+this.cat_id;
      this.getfullurl = encodeURI('/catlog'+stringURL);
      // location.pathname();
      //  alert(this.getfullurl)
      // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
      this.parts = data["Data"];
      // this.iscart = data["Data"][0]["isCart"]
         // console.log(data["Data"][0]["isCart"]);
          for(let i=0;i<this.parts.length;i++){
            if(data["Data"][i]["isCart"] ==true){
              this.parts[i]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else if(data["Data"][i]["isCart"] ==false){
             // this.atcshow = true;
             this.parts[i]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          }
      }) */ 
      this.filterapiv2();
       
    }




      // When searching directly without model    
      if (URLElement!=null && URLElement2 == null) {
        //this.loader = true;
        this.api.getproductbycategory(URLElement).subscribe(
          data => { 
            //alert("hello")
            this.parts = data["Data"];
          //this.loading =false;
          console.log("Show data by category only  "+this.parts);
          this.getpartdatawithiscart();
          });
      }
      //show all products directly  without model  and without category
      else if(URLElement== null && URLElement2 ==null && this.cat_id == null && brand_id==null && offercode==null){
        this.loading =true;
        this.filterapiv2();
       // alert("Helo");
        // this.parts = null;
        // piyush comment bcz part display with iscart true or false 23/07/2021
        
      /* if(sessionStorage.getItem('CustomerId')){
        this.api.cartDetails(customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            this.cartpartid = data["Data"][0]["PartId"];
            console.log(this.pdetails)
            
            //this.cartid = data["Data"][0["CartId"]]
            console.log(data["Data"]);
            //this.testing();
            //this.reloadComponent();
            //console.log(this.cartid)

            
          });
      }
      else{
        this.router.navigate(['/login']);
      } */

      //alert("part else if")
        /* this.api.getallparts().subscribe((data)=>{
        this.parts = data["Data"];
        this.allparid = data["Data"][0]["PartId"];
        //alert("Hello")
        console.log("Show all produts  "+data["Data"][0]["product"]);
       // this.getpartdatawithiscart();
       /*  for(let k=0;k<this.parts.length;k++)
        {
          for(let i=0;i<this.pdetails.length;i++)
          {
            if(this.parts[k]["PartId"] == this.pdetails[i]["PartId"])
            {
              //alert("hllo")
              //return true;
              this.parts[k]["Show"]  = true;
            
            }
            else{
              //alert("hllo11")
              this.parts[k]["Show"] = false;
            }
          }
        } 
        }); */
        //piyush end
        //apply filter api 14/09/2021

        //old
        /* var customerid = sessionStorage.getItem('CustomerId');
        var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+'0'+'&MaxPrice='+ '50000' +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id;
      this.getfullurl = encodeURI('/catlog'+stringURL);
      // location.pathname();
      //  alert(this.getfullurl)
      // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
        this.loading =false;
      this.parts = data["Data"];
        this.totallength =this.parts.length;
        
      // this.iscart = data["Data"][0]["isCart"]
         // console.log(data["Data"][0]["isCart"]);
          for(let i=0;i<this.parts.length;i++){
            if(data["Data"][i]["isCart"] ==true){
              this.parts[i]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else if(data["Data"][i]["isCart"] ==false){
             // this.atcshow = true;
             this.parts[i]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          }
      })  */
      
        //end
        //piyush iscart wise part display 23/07/2021
       // alert("hello")
       //alert("hell")
      // this.getpartdatawithiscart();


        //piyush end


      }
      // show  when searching in model  by category
      else if(URLElement!= null && URLElement2 !=null)
      {
        this.api.getpartbycategoryandmodelno(URLElement,URLElement2).subscribe(
          data => {this.parts = data["Data"];
         // this.loading =false;
          console.log("Show data by  model category "+this.parts);
          this.getpartdatawithiscart();
         });


      }
      else{
       // this.selectedItem =this.brand_id;
       // let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id } });
       // alert("piyush")
       // this.filterapiv2();
      }

     /*  else if(BrandElement!= null && URLElement== null )
      {
        this.api.getproductbybrand(BrandElement).subscribe(
          data => {this.parts = data["Data"];
          this.loading =false;
          console.log("Show data by  brand "+this.parts);
         });


      } */      
    /* this.api.getBrandDataV2(this.modification3,this.modification5).subscribe((data)=>{
    this.brands = data["Data"];
    }); */

/* this.api.getallcategory().subscribe((categorydata)=>{
  this.categorys = categorydata["Data"];
}) */

// this.api.getsubcategorydata().subscribe((categorydata)=>{
//   this.subcategorys = categorydata["Data"];
// })

/* this.api.getCategoryDataWithSubcategoryData(this.modification3,this.modification5).subscribe((data)=>{
  this.allcategorys = data["Data"][0]["Category"];
  /* console.log(data["Data"]);
  console.log(data["Data"][0]["Category"]);
  console.log(data["Data"][0]["Category"][0]["CategoryName"]);
  console.log(data["Data"][0]["Category"][0]["subcategory"]);
  console.log(data["Data"][0]["Category"][0]["subcategory"][0]["SubcategoryName"]); 
}) */

this.getCategoryDataWithSubcategoryDataV3();
this.getBrandDataV3();
//this.minprice = this.getrange();

//this.getrange();
this.minprice = 0;
this.maxprice = 50000;
/* this.loading = false; */

//this.getpartdatawithiscart();
//for filter
//  this.filterapiv2();

//for form auto fill 

}
sliderEvent(){
 // alert(this.sliderValue);
 // alert("hello")
 this.minprice = this.mylowvalue;
 this.maxprice =this.myhighvalue;
 this.filterapiv2();

}

toggleShow(element) {
  this.isShown = ! this.isShown;
  this.button = element;
  if(this.button.textContent != "Apply filter"){     
    this.button.textContent = 'Apply filter'; 
     // this.atc = "Already in Cart";
     // this.addcart(pd);
      //this.refresh();
    }

}
getBrandDataV3(){ 
  this.api.getBrandDataV3(this.modification3,this.modification5,this.cat_id,this.Sub).subscribe((data)=>{
    this.brands = data["Data"];
    });

}

getCategoryDataWithSubcategoryDataV3(){
  this.api.getCategoryDataWithSubcategoryData(this.modification3,this.modification5).subscribe((data)=>{
    this.allcategorys = data["Data"][0]["Category"];
    /* console.log(data["Data"]);
    console.log(data["Data"][0]["Category"]);
    console.log(data["Data"][0]["Category"][0]["CategoryName"]);
    console.log(data["Data"][0]["Category"][0]["subcategory"]);
    console.log(data["Data"][0]["Category"][0]["subcategory"][0]["SubcategoryName"]); */
  })
}

filterapiv2(){
  var customerid = sessionStorage.getItem('CustomerId');
  
  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id+'&SPHL='+this.sortselect+'&SPLH='+this.sortselect+'&SAZ='+this.sortselect+'&SZA='+this.sortselect;
  this.getfullurl = encodeURI('/catlog'+stringURL);
// location.pathname();
//  alert(this.getfullurl)
// alert(a)

this.api.filterv2(this.getfullurl).subscribe((data)=>{
  this.loading =false;
this.parts = data["Data"];
if(this.parts ==''){
  // alert("hello")
   this.pagenf = true;
 }
 else{
   this.pagenf =false;
 }
this.totallength =this.parts.length;
// this.iscart = data["Data"][0]["isCart"]
   // console.log(data["Data"][0]["isCart"]);
    for(let i=0;i<this.parts.length;i++){
      if(data["Data"][i]["isCart"] ==true){
        this.parts[i]["atcshow"] = false;
        console.log("Product in already cart")
      }
      else if(data["Data"][i]["isCart"] ==false){
       // this.atcshow = true;
       this.parts[i]["atcshow"] = true;
        console.log("Product in not already cart ")
      } 
    }

    this.categorynamen = data["CategoryData"];

}) 


}
onChangesorting(e){
  var customerid = sessionStorage.getItem('CustomerId');
  this.sortselect =e;
  if(this.sortselect==1){
    this.loading =true;
    let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id,'SPHL':this.sortselect } });
    //this.filterapiv2();
    var customerid = sessionStorage.getItem('CustomerId');
  
    var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id+'&SPHL='+this.sortselect+'&SPLH='+''+'&SAZ='+''+'&SZA='+'';
    this.getfullurl = encodeURI('/catlog'+stringURL);
  // location.pathname();
  //  alert(this.getfullurl)
  // alert(a)
  
  this.api.filterv2(this.getfullurl).subscribe((data)=>{
    this.loading =false;
  this.parts = data["Data"];
  this.totallength =this.parts.length;
  // this.iscart = data["Data"][0]["isCart"]
     // console.log(data["Data"][0]["isCart"]);
      for(let i=0;i<this.parts.length;i++){
        if(data["Data"][i]["isCart"] ==true){
          this.parts[i]["atcshow"] = false;
          console.log("Product in already cart")
        }
        else if(data["Data"][i]["isCart"] ==false){
         // this.atcshow = true;
         this.parts[i]["atcshow"] = true;
          console.log("Product in not already cart ")
        } 
      }
  }) 
  
  }
  else if(this.sortselect==2){
    this.loading =true;
    let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id,'SPLH':this.sortselect } });
    //this.filterapiv2();
    var customerid = sessionStorage.getItem('CustomerId');
  
    var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id+'&SPHL='+''+'&SPLH='+this.sortselect+'&SAZ='+''+'&SZA='+'';
    this.getfullurl = encodeURI('/catlog'+stringURL);
  // location.pathname();
  //  alert(this.getfullurl)
  // alert(a)
  
  this.api.filterv2(this.getfullurl).subscribe((data)=>{
    this.loading =false;
  this.parts = data["Data"];
  this.totallength =this.parts.length;
  // this.iscart = data["Data"][0]["isCart"]
     // console.log(data["Data"][0]["isCart"]);
      for(let i=0;i<this.parts.length;i++){
        if(data["Data"][i]["isCart"] ==true){
          this.parts[i]["atcshow"] = false;
          console.log("Product in already cart")
        }
        else if(data["Data"][i]["isCart"] ==false){
         // this.atcshow = true;
         this.parts[i]["atcshow"] = true;
          console.log("Product in not already cart ")
        } 
      }
  }) 
  
  }
  else if(this.sortselect==3){
    this.loading =true;
    let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id,'SAZ':this.sortselect } });
    //this.filterapiv2();
    var customerid = sessionStorage.getItem('CustomerId');
  
    var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id+'&SPHL='+''+'&SPLH='+''+'&SAZ='+this.sortselect+'&SZA='+'';
    this.getfullurl = encodeURI('/catlog'+stringURL);
  // location.pathname();
  //  alert(this.getfullurl)
  // alert(a)
  
  this.api.filterv2(this.getfullurl).subscribe((data)=>{
    this.loading =false;
  this.parts = data["Data"];
  this.totallength =this.parts.length;
  // this.iscart = data["Data"][0]["isCart"]
     // console.log(data["Data"][0]["isCart"]);
      for(let i=0;i<this.parts.length;i++){
        if(data["Data"][i]["isCart"] ==true){
          this.parts[i]["atcshow"] = false;
          console.log("Product in already cart")
        }
        else if(data["Data"][i]["isCart"] ==false){
         // this.atcshow = true;
         this.parts[i]["atcshow"] = true;
          console.log("Product in not already cart ")
        } 
      }
  }) 
  
  }
  else if(this.sortselect==4){
    this.loading =true;
    let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id,'SZA':this.sortselect } });
   // this.filterapiv2();
   var customerid = sessionStorage.getItem('CustomerId');
  
   var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id+'&SPHL='+''+'&SPLH='+''+'&SAZ='+''+'&SZA='+this.sortselect;
   this.getfullurl = encodeURI('/catlog'+stringURL);
 // location.pathname();
 //  alert(this.getfullurl)
 // alert(a)
 
 this.api.filterv2(this.getfullurl).subscribe((data)=>{
   this.loading =false;
 this.parts = data["Data"];
 this.totallength =this.parts.length;
 // this.iscart = data["Data"][0]["isCart"]
    // console.log(data["Data"][0]["isCart"]);
     for(let i=0;i<this.parts.length;i++){
       if(data["Data"][i]["isCart"] ==true){
         this.parts[i]["atcshow"] = false;
         console.log("Product in already cart")
       }
       else if(data["Data"][i]["isCart"] ==false){
        // this.atcshow = true;
        this.parts[i]["atcshow"] = true;
         console.log("Product in not already cart ")
       } 
     }
 }) 
 
  }

  //alert(e)
  

}

getrange(){
 // alert("hello")

  var customerid = sessionStorage.getItem('CustomerId');
  /* this.loading = true; */
 // alert("Hello");
  this.minprice= (document.getElementsByClassName("noUi-handle")[0]).getAttribute('aria-valuenow');
 this.maxprice= (document.getElementsByClassName("noUi-handle")[1]).getAttribute('aria-valuenow');

 /* this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice } });

 this.api.filter(this.selectedItem,this.minprice ,this.maxprice,this.customerid).subscribe((data)=>{
  this.parts = data["Data"];
  this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);
            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
}) */
                /* this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub,'Cat':this.cat_id } });
                var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id;
              this.getfullurl = encodeURI('/catlog'+stringURL);
       // location.pathname();
      //  alert(this.getfullurl)
     // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
        this.parts = data["Data"];
       // this.iscart = data["Data"][0]["isCart"]
           // console.log(data["Data"][0]["isCart"]);
            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
      })  */
      this.filterapiv2();
//this.getpartdatawithiscart();
//alert(min);
//return min;
}

//piyush for filter form data 26/07/2021
onChangemakersType(args) {
 // alert(event)
  let MakerstypeId = args.target.value; 
  //alert(MakerstypeId)
  this.makerstypename1 = args.target.options[args.target.selectedIndex].text; 
 // console.log(this.makerstypename1)
  //this.makerstypename1 = MakerstypeId;
  //console.log(StateId);
  if (MakerstypeId!=null) {
    this.homeapi.getmakersdata(MakerstypeId).subscribe(
      data => this.makers = data["Data"]
    );
  } else {
    this.makers = null;
  }
}


onChangemakers(args) {
 // alert(makersid)
 let makersid = args.target.value; 
 // alert(makersid)
  this.makersname1 = args.target.options[args.target.selectedIndex].text; 
 // console.log(this.makersname1)
  if (makersid!=null) {
    this.homeapi.getmodeldata(makersid).subscribe(
      data => this.models = data["Data"]
    );
  } else {
    this.models = null;
  }
}


onChangeModel(args) {
  //console.log(StateId);
  let modelid = args.target.value; 
 // alert(modelid)
 this.ModelId = modelid;
 this.ModificationId = '';
  this.modelname1 = args.target.options[args.target.selectedIndex].text; 
 // console.log(this.modelname1)
  if (modelid!=null) {
    this.homeapi.getmodificationdata(modelid).subscribe(
      data2 => this.modifications = data2["Data"]
    );
  } else {
    this.modifications = null;
  }
}
onChangeyear(args){
  let year = args.target.value; 
 // alert(modelid)
 this.ModificationId = year;
  this.year1 = args.target.options[args.target.selectedIndex].text; 
 // console.log(this.year1)
}

onChangemodification(args){
  let modelid = args.target.value; 
 // alert(modelid)
  this.modification1 = args.target.options[args.target.selectedIndex].text; 
 // console.log(this.modification1)
}


 getpartdatawithiscart(){
  var customerid = sessionStorage.getItem('CustomerId');
    console.log(customerid)
    //var a = this.uparid;
      //var partQty=category.PartQty;
      if(sessionStorage.getItem('CustomerId')){
        //alert("If")
       this.api.getAllProductDataWithCartData(customerid).subscribe(
          data => {
            //this.parts = data["Data"];
           // this.iscart = data["Data"][0]["isCart"]
            //console.log(data["Data"][0]["isCart"]);

            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }

          
          });
         // alert("If")
      }
      else{

        //alert("hello")
        this.api.getAllProductDataWithCartData(customerid).subscribe(
          data => {
            //this.parts = data["Data"];
          //  this.iscart = data["Data"][0]["isCart"]
         //   console.log(data["Data"][0]["isCart"]);

             

            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true)
              {
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false)
              {
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }

          
          });
        //piyush change code for without session display part 31/07/2021
        //here is changes 
        /*  this.api.getallparts().subscribe((data)=>{
            this.parts = data["Data"];
            this.allparid = data["Data"][0]["PartId"];
            console.log("Show all produts  "+data["Data"][0]["product"]);
          // this.parts["atcshow"] = true;
          for(let i=0;i<this.parts.length;i++){
            this.parts[i]["atcshow"] = true;
          }
            });  */
        
       /*  if(customerid == null){
          alert(customerid)
        } */
        /* this.api.getAllProductDataWithCartData(customerid).subscribe(
          data => {
            //this.pdetails = data["Data"];
            this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);

            if(this.iscart ==false){
              this.atcshow = true;
              console.log("Product in not already cart ")
            } 

          
          }); */
         // alert("Else")
        //this.router.navigate(['/login']);
        
      }
}

 onChangeCategory(CategoryId: number) {
  //console.log(StateId);
  if (CategoryId!=null) {
    this.api.getsubcategorydata(CategoryId).subscribe(
      data => this.subcategorys = data["Data"]
    );
  } else {
    this.subcategorys = null;
  }
} 

/* onChangeCategory(CategoryId: number) {
  //console.log(StateId);
  if (CategoryId!=null) {
    
  } else {
    this.subcategorys = null;
  }
} */


  getbrandid(e:any,BrandId:string){
   // this.loading = true;
    if(e.target.checked){
      var customerid = sessionStorage.getItem('CustomerId');
      console.log(BrandId +'checked');
      this.selectedItem.push(BrandId);
      let all = this.selectedItem;

      //auto select checkbox
   //   alert(this.brand_id)
   this.loading =true;
     /* this.api.getpartsbybrand(this.selectedItem).subscribe((data)=>{
      this.parts = data["Data"];
      }) */
      //piyush start 03/09/2021
      /* let params = new HttpParams();
    if(this.selectedItem !=''){
      params = params.append('BrandId',BrandId);
    }
    
    if(this.minprice != ''){
      params = params.append('MinPrice',MinPrice);
    }
    if(this.maxprice !=''){
      params = params.append('MaxPrice',MaxPrice);
    } */
   // this.router.navigateByUrl
     let navigate = this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub ,'Cat':this.cat_id } });
      /* const queryParams: any = {}; 
      // Add the array of values to the query parameter as a JSON string
    queryParams.myArray = JSON.stringify(this.selectedItem);

    // Create our 'NaviationExtras' object which is expected by the Angular Router
    const navigationExtras: NavigationExtras = {
      queryParams
    };

    // Navigate to component B
    this.router.navigate(['/componentB'], navigationExtras); */
      

      //end
     /*  if(navigate !=null){
       
      } */
     // alert(navigate)
     //alert("hello")
      let URL;
  
        // URL=this.router.url;
        // URL=this.router.url.split('/');
        // URL=this.router.url.split('/');
        
        //console.log(URL[1]);
        //old
       /*  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id;
        this.getfullurl = encodeURI('/catlog'+stringURL);
       // location.pathname();
      //  alert(this.getfullurl)
     // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
        this.parts = data["Data"];
       // this.iscart = data["Data"][0]["isCart"]
           // console.log(data["Data"][0]["isCart"]);
            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
      })  */
     
      this.filterapiv2();
     // this.getpartdatawithiscart();      

      
    }
     else{
       console.log(BrandId +'unchecked');
      /* for(let i=0;i<this.selectedItem.length;i++){
        if(BrandId == this.selectedItem[i]){
          this.selectedItem[i] 
        }
      } */

      var index = this.selectedItem.indexOf(BrandId);
      this.selectedItem.splice(index,1);
      var customerid = sessionStorage.getItem('CustomerId');
      this.loading =true;
      this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.Sub,'Cat':this.cat_id } });

       /* var re = BrandId; 
       var newstr = this.selectedItem.replace(re, ""); 
       console.log(newstr)  */      
       //this.selectedItem.push(BrandId);
       //old 
      /*  let URL;
       URL=this.router.url.split('/');
      let fullurl = encodeURI(URL)

       this.api.filterv2(fullurl).subscribe((data)=>{
        this.parts = data["Data"];
        this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);
            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
      })  */
      //new
      //old
     /*  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id;
        this.getfullurl = encodeURI('/catlog'+stringURL);
       // location.pathname();
      //  alert(this.getfullurl)
     // alert(a)
      
      this.api.filterv2(this.getfullurl).subscribe((data)=>{
        this.parts = data["Data"];
       // this.iscart = data["Data"][0]["isCart"]
           // console.log(data["Data"][0]["isCart"]);
            for(let i=0;i<this.parts.length;i++){
              if(data["Data"][i]["isCart"] ==true){
                this.parts[i]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][i]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[i]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
      })  */
      this.filterapiv2();

     /* this.selectedItem = this.selectedItem.filter(m=>m!=BrandId); */
    } 
    //console.log(this.selectedItem)
    
  }

  loadingcheck(){
    //alert("hello")
    //this.loading =true;
  }
  onClickSubmit(data){


    this.api.getModificationDataByModelIdOrModificationId(this.ModelId,this.ModificationId).subscribe((res)=>{
      this.formdata =res["Data"];
      this.makerstypename =res["Data"][0]["MakerstypeName"];
      this.makersname =res["Data"][0]["MakersName"];
      this.modelname =res["Data"][0]["ModelName"];
      this.year =res["Data"][0]["ModificationStartYear"];
      this.modificationname =res["Data"][0]["ModificationName"];
      this.model3 = res["model3"];
      //alert(this.model3)
      console.log(this.makersname)
      this.homepagebreadcrumb5 = true;
      // let a = [this.makerstypename,this.makersname,this.modelname,this.year,this.modificationname]
     /* localStorage.setItem('pdetailbreadcrumb',a); */
    //alert(a[0])

        
      //  alert(this.year)
      if(this.model3 ==1){
        //alert("hello")
     // this.year = '';
        
     // this.model3 = '';
      
      this.homepagebreadcrumb3 = true;    
      this.homepagebreadcrumb5 = false;    
      }
      if(this.year !=null){
       // alert("year")
        this.homepagebreadcrumb5 = true;
        this.homepagebreadcrumb3 = false;
      }
      localStorage.setItem('1',this.makerstypename);
      localStorage.setItem('2',this.makersname);
      localStorage.setItem('3',this.modelname);
      localStorage.setItem('4',this.year);
      localStorage.setItem('5',this.modificationname);

      let storeDataGet:any;
        storeDataGet.push(this.makerstypename,this.makersname,this.modelname,this.year,this.modificationname);
        localStorage.setItem('pdetailbreadcrumb',JSON.stringify(storeDataGet));
      
    })
    //his.isShown =false;t
    if (window.screen.width <= 360) { // 768px portrait
      this.isShown = false;

    }
    else{
      this.isShown = true;

    }

    //this.homepagebreadcrumb5 = false;
    /* this.breadcrumbshow3 = true;
    if(this.year1 !=null){
      this.breadcrumbshow5 = true;
      this.breadcrumbshow3 = false;
    } */
    
    var customerid = sessionStorage.getItem('CustomerId');
    //console.log(data.check1)
    //console.log(data.modification)
    this.loading =true;
    //console.log(this.selectedItem)
   // this.router.navigate(['/vehicle',data.model,data.year]);

  /*  this.api.searchByFormWithPartData(data.model,data.year).subscribe((data)=>{
     this.parts = data["Data"];
   }) */
   this.modification3 = data.model;
   this.modification5 = data.year;
   if(this.modification5 ==''){
    this.router.navigate(['/catlog'], { queryParams: { 'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'modification3':this.modification3,'modification5':this.modification5} });
    this.getCategoryDataWithSubcategoryDataV3();
    //old 12/10/2021
   // this.getBrandDataV3();
   //change for catid not pass in this api 
   this.api.getBrandDataV3(this.modification3,this.modification5,'','').subscribe((data)=>{
    this.brands = data["Data"];
    });
   }
   else{
    this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5 } });
    this.getCategoryDataWithSubcategoryDataV3();
   // this.getBrandDataV3();
    this.api.getBrandDataV3(this.modification3,this.modification5,'','').subscribe((data)=>{
      this.brands = data["Data"];
      });
   }
 // this.router.navigate(['/catlog'], { queryParams: { 'BrandId':JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':this.customerid,'modification3':this.modification3,'modification5':this.modification5}});

  /* let URL;
  
  URL=this.router.url;
  this.getfullurl = encodeURI(URL)
 // alert(this.getfullurl)
 //alert(URL);

this.api.filterv2(this.getfullurl).subscribe((data)=>{
  this.parts = data["Data"];
 // this.iscart = data["Data"][0]["isCart"]
     // console.log(data["Data"][0]["isCart"]);
      for(let i=0;i<this.parts.length;i++){
        if(data["Data"][i]["isCart"] ==true){
          this.parts[i]["atcshow"] = false;
          console.log("Product in already cart")
        }
        else if(data["Data"][i]["isCart"] ==false){
         // this.atcshow = true;
         this.parts[i]["atcshow"] = true;
          console.log("Product in not already cart ")
        } 
      }
})  */
//new
//old
/* var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.cat_id;
this.getfullurl = encodeURI('/catlog'+stringURL);
// location.pathname();
//  alert(this.getfullurl)
// alert(a)

this.api.filterv2(this.getfullurl).subscribe((data)=>{
this.parts = data["Data"];
// this.iscart = data["Data"][0]["isCart"]
   // console.log(data["Data"][0]["isCart"]);
    for(let i=0;i<this.parts.length;i++){
      if(data["Data"][i]["isCart"] ==true){
        this.parts[i]["atcshow"] = false;
        console.log("Product in already cart")
      }
      else if(data["Data"][i]["isCart"] ==false){
       // this.atcshow = true;
       this.parts[i]["atcshow"] = true;
        console.log("Product in not already cart ")
      } 
    }
})  */
  //this.filterapiv2();
  this.submitfilterapi();


  }
  submitfilterapi(){
    this.Sub = '';
    this.selectedItem = [];
    var customerid = sessionStorage.getItem('CustomerId');
  
  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+''+'&Cat='+''+'&SPHL='+this.sortselect+'&SPLH='+this.sortselect+'&SAZ='+this.sortselect+'&SZA='+this.sortselect;
  this.getfullurl = encodeURI('/catlog'+stringURL);
// location.pathname();
//  alert(this.getfullurl)
// alert(a)

this.api.filterv2(this.getfullurl).subscribe((data)=>{
  this.loading =false;
this.parts = data["Data"];
if(this.parts ==''){
  // alert("hello")
   this.pagenf = true;
 }
 else{
   this.pagenf =false;
 }
this.totallength =this.parts.length;
// this.iscart = data["Data"][0]["isCart"]
   // console.log(data["Data"][0]["isCart"]);
    for(let i=0;i<this.parts.length;i++){
      if(data["Data"][i]["isCart"] ==true){
        this.parts[i]["atcshow"] = false;
        console.log("Product in already cart")
      }
      else if(data["Data"][i]["isCart"] ==false){
       // this.atcshow = true;
       this.parts[i]["atcshow"] = true;
        console.log("Product in not already cart ")
      } 
    }

    this.categorynamen = data["CategoryData"];

}) 

  }
  getcheckboxvalue(e:any){
    if(e.target.checked){
      console.log(e.target.value)
      //console.log( 'checked')
    }
    else{
      //console.log(e.target.value)
      console.log('unchecked')
    }
    }
    
    toogle(element,pd){
      //alert(element);
      //this.show = !this.show;
     // CHANGE THE NAME OF THE BUTTON.
     //var partId=pd.PartId;
     //alert(partId)
     //alert(pd);
     this.button = element;
     if(this.button.textContent != "Added to cart"){     
     this.button.textContent = 'Added to cart'; 
      // this.atc = "Already in Cart";
       this.addcart(pd);
       //this.refresh();
     }
     else{
      Swal.fire({
        title: 'Thanks',
        text: 'Product already in cart ',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Go to Cart',
        confirmButtonColor: '#FD8E01',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/cart']);
        } else if (result.dismiss === Swal.DismissReason.close) {
        } 
      })
     }
       
   } 
   tooglemoq(element,pd){
    //alert(element);
    //this.show = !this.show;
   // CHANGE THE NAME OF THE BUTTON.
   //var partId=pd.PartId;
   //alert(partId)
   //alert(pd);
   this.button = element;
   if(this.button.textContent != "Added to cart"){     
   this.button.textContent = 'Added to cart'; 
    // this.atc = "Already in Cart";
     this.addcartmoq(pd);
   }
   else{
    Swal.fire({
      title: 'Thanks',
      text: 'Product already in cart ',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Go to Cart',
      confirmButtonColor: '#FD8E01',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/cart']);
      } else if (result.dismiss === Swal.DismissReason.close) {
      } 
    })
   }  
 } 
 refresh(): void {
      
  window.location.reload();
  
  //window.location.replace("/home");
}

    
    
itemsCart : any = [];
addcart(category)
  {
    if(sessionStorage.getItem('CustomerId')){

      
    //old  
    this.uparid = category.PartId;
      //this.uparid = category.PartId;
      //console.log(category);
      let cartDataNull = localStorage.getItem('localcart');
      if(cartDataNull == null){
        let storeDataGet:any  =[];
        storeDataGet.push(category);
        localStorage.setItem('localcart',JSON.stringify(storeDataGet));
      }
      else{
        var partId=category.PartId;
        var partQty = category.PartQty;
        
        let index:number = -1;
        let counter=0;
        this.itemsCart = JSON.parse(localStorage.getItem('localcart'));
        for(let i=0;i<this.itemsCart.length;i++){
          if(parseInt(partId) === parseInt(this.itemsCart[i].PartId)){
            this.itemsCart[i].PartQty = category.PartQty;
            index = i;
            break;

          }
        }
        if(index == -1){
          this.itemsCart.push(category);
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
        else{
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
      }

     // this.cartNumberFunc();
      
      //localStorage.setItem('localcart',JSON.stringify(category));
      var customerid = sessionStorage.getItem('CustomerId');
      var partId=category.PartId;
      //var partQty=category.PartQty;
      //if(partId == )
      /* if(sessionStorage.getItem('CustomerId')){ */
        this.api1.addtocartByProductDeatil(customerid,partId,1).subscribe(
          data => {
          this.pdetails = data["Data"];
          if(data["Count"]==1){
          //  alert("hello");
            this.counter();
          }
          //this.parts =data["Data"]  

          //for iscart check 02/09/2021
          //alert(data["Data"][0]["isCart"])
          /* if(data["Data"][0]["PartId"] !=null){
            if(data["Data"][0]["isCart"] ==true){
              this.parts[0]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else if(data["Data"][0]["isCart"] ==false){
             // this.atcshow = true;
             this.parts[0]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          } */
          /* for(let i=0;i<this.pdetails.length;i++){
            if(data["Data"][0]["PartId"]== this.allparid){
              if(data["Data"][0]["isCart"] ==true){
                this.parts[0]["atcshow"] = false;
                console.log("Product in already cart")
              }
              else if(data["Data"][0]["isCart"] ==false){
               // this.atcshow = true;
               this.parts[0]["atcshow"] = true;
                console.log("Product in not already cart ")
              } 
            }
            
          } */
          //this.aic =true;
         // this.getpartdatawithiscart();
          //this.ngOnInit();
          //this.a = true;
          //alert("if 1")
          /*let apid = data["Data"][0]["PartId"];
           for(let i=0;i<this.pdetails.length;i++){
            if(data["Data"][i]["PartId"] !=null){
              this.pdetails[i]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else{
             // this.atcshow = true;
             this.pdetails[i]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          } */
          //alert(apid)
          /* if(apid !=null){
            this.a = true;
          }  */ 
          
         
          //alert("if 2")
          //this.checkstock();
         
          });
         
  
          this.reloadComponent();
      }
     
      else{
        
        this.router.navigate(['/login']);
      }
      

      
      
  }
  addcartmoq(category){
    if(sessionStorage.getItem('CustomerId')){
      /* //new
    var customerid = sessionStorage.getItem('CustomerId');
    this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
      this.count =data["Data"];
     //alert(this.count)
     console.log("count"+this.count)
     this.cartNumberFunc();
     }) */
    
  //old
    let cartDataNull = localStorage.getItem('localcart');
      if(cartDataNull == null){
        let storeDataGet:any  =[];
        storeDataGet.push(category);
        localStorage.setItem('localcart',JSON.stringify(storeDataGet));
      }
      else{
        var partId=category.PartId;
        var partQty = category.PartQty;
        let index:number = -1;
        this.itemsCart = JSON.parse(localStorage.getItem('localcart'));
        for(let i=0;i<this.itemsCart.length;i++){
          if(parseInt(partId) === parseInt(this.itemsCart[i].PartId)){
            this.itemsCart[i].PartQty = category.PartQty;
            index = i;
            break;

          }
        }
        if(index == -1){
          this.itemsCart.push(category);
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
        else{
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
      }

      //this.cartNumberFunc();
      
      //localStorage.setItem('localcart',JSON.stringify(category));
      var customerid = sessionStorage.getItem('CustomerId');
      var partId=category.PartId;
      var partMoq = category.PartMoq;
      //var partQty=category.PartQty;
     /*  if(sessionStorage.getItem('CustomerId')){ */
        this.api1.addtocartByProductDeatil(customerid,partId,partMoq).subscribe(
          data => {
          this.pdetails = data["Data"];
          if(data["Count"]==1){
            //  alert("hello");
              this.counter();
            }
          //alert("if 1")
          this.reloadComponent();
  //        this.getpartdatawithiscart();
          //alert("if 2")
          //this.checkstock();
         
          });
      }
     
      else{
        
        this.router.navigate(['/login']);
      }
  }
  counter(){
     //new
     var customerid = sessionStorage.getItem('CustomerId');
     this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
       this.count =data["Data"];
      //alert(this.count)
      console.log("count"+this.count)
      this.cartNumberFunc();
      })
  }
  checkstock(){

    var customerid = sessionStorage.getItem('CustomerId');
    console.log(customerid)
    //var a = this.uparid;
      //var partQty=category.PartQty;
      //console.log("uparid:"+this.uparid)
      if(sessionStorage.getItem('CustomerId')){

        this.api1.lateststockwithcartdata(this.uparid,customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            /* this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);

             if(this.iscart ==true){
              this.atcshow = false;
              console.log("Product in already cart")
            }
            else if(this.iscart ==false){
              this.atcshow = true;
              console.log("Product in not already cart ")
            }  */

          
          });
      }
      else{
       /*  if(customerid == null){
          alert(customerid)
        } */
        this.api1.lateststockwithcartdata(this.uparid,customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);

            if(this.iscart ==false){
              this.atcshow = true;
              console.log("Product in not already cart ")
            } 

          
          });
       // this.router.navigate(['/login']);
      }
  }
  cartNumber :number =0;
  cartNumberFunc(){
    //alert("hello")
    var cartValue =JSON.parse(localStorage.getItem('localcart'));
    //this.cartNumber =cartValue.length;
    this.cartNumber = this.count;
    this.api1.cartSubject.next(this.cartNumber);
    console.log(this.cartNumber);
  } 
    

  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

    //piyush for alert 24/07/2021
    showsweetalert(){
      /* Swal.fire({
        title: 'Thanks',
        text: 'Product already in cart ',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Go to Cart',
        
      }).then((result) => {
        this.router.navigate(['/cart']);
        
      }) */

      Swal.fire({
        title: 'Thanks',
        text: 'Product already in cart ',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Go to Cart',
        confirmButtonColor: '#FD8E01',
        
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/cart']);
        
        } else if (result.dismiss === Swal.DismissReason.close) {
          
        }
        
      })
    }

    getpartbysubcategory(scid){
      this.Sub =scid.SubcategoryId;
      this.categoryid_sub = scid.CategoryId;
     // alert(a)
      this.loading =true;
      var customerid = sessionStorage.getItem('CustomerId');
      this.router.navigate(['/catlog'], { queryParams: { BrandId:JSON.stringify(this.selectedItem)  ,'MinPrice':this.minprice, 'MaxPrice': this.maxprice ,'CustomerId':customerid,'modification3':this.modification3,'modification5':this.modification5,'Sub':this.categoryid_sub+"-"+this.Sub } });
     // alert('scid :'+scid.SubcategoryId);
     /*  var customerid = sessionStorage.getItem('CustomerId');
      this.api.getPartDataBySubcategoryId(customerid,scid.SubcategoryId).subscribe((data)=>{
        this.parts = data["Data"];
      })
         this.getpartdatawithiscart(); */
      
      //this.filterapiv2();   
      //for new brand
      var customerid = sessionStorage.getItem('CustomerId');
  
      var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub+'&Cat='+this.categoryid_sub+'&SPHL='+this.sortselect+'&SPLH='+this.sortselect+'&SAZ='+this.sortselect+'&SZA='+this.sortselect;
      this.getfullurl = encodeURI('/catlog'+stringURL);
    // location.pathname();
    //  alert(this.getfullurl)
    // alert(a)
    
    this.api.filterv2(this.getfullurl).subscribe((data)=>{
      this.loading =false;
    this.parts = data["Data"];
    if(this.parts ==''){
      // alert("hello")
       this.pagenf = true;
     }
     else{
       this.pagenf =false;
     }
    this.totallength =this.parts.length;
    // this.iscart = data["Data"][0]["isCart"]
       // console.log(data["Data"][0]["isCart"]);
        for(let i=0;i<this.parts.length;i++){
          if(data["Data"][i]["isCart"] ==true){
            this.parts[i]["atcshow"] = false;
            console.log("Product in already cart")
          }
          else if(data["Data"][i]["isCart"] ==false){
           // this.atcshow = true;
           this.parts[i]["atcshow"] = true;
            console.log("Product in not already cart ")
          } 
        }
    
        this.categorynamen = data["CategoryData"];
    
    })
      //this.getBrandDataV3();
      //new for brand 
      this.api.getBrandDataV3(this.modification3,this.modification5,this.categoryid_sub,this.Sub).subscribe((data)=>{
        this.brands = data["Data"];
        });
     /*  var stringURL ="?BrandId="+JSON.stringify(this.selectedItem)+'&MinPrice='+this.minprice+'&MaxPrice='+ this.maxprice +'&CustomerId='+customerid +'&modification3='+this.modification3 +'&modification5='+this.modification5 +'&Sub='+this.Sub;
      this.getfullurl = encodeURI('/catlog'+stringURL);
     // location.pathname();
    //  alert(this.getfullurl)
   // alert(a)
    
    this.api.filterv2(this.getfullurl).subscribe((data)=>{
      this.parts = data["Data"];
     // this.iscart = data["Data"][0]["isCart"]
         // console.log(data["Data"][0]["isCart"]);
          for(let i=0;i<this.parts.length;i++){
            if(data["Data"][i]["isCart"] ==true){
              this.parts[i]["atcshow"] = false;
              console.log("Product in already cart")
            }
            else if(data["Data"][i]["isCart"] ==false){
             // this.atcshow = true;
             this.parts[i]["atcshow"] = true;
              console.log("Product in not already cart ")
            } 
          }
    })  */
    }
    resetform(){
       // alert("hello")
        this.router.navigate(['/catlog']);
        /* this.router.navigate(['/login']); */
       // this.refresh();
      // window.location.replace("/catlog");
      
      this.router.navigate(['/catlog'])
      .then(() => {
           window.location.reload();
       });
    }

    

}

/* Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    }) */

  /* onsubmit(data){
    console.log(data.productid);
  }
   
  onChangeCategory() {
    
  } */

  


