import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { BrandsComponent } from './brands.component';

@NgModule({
  declarations: [BrandsComponent],
  exports:[ BrandsComponent ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
  ],
  
})
export class BrandsModule { }
