import { Component, OnInit } from '@angular/core';
import { getproductService } from './getproduct.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../global-constant';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  products : {};
  scproducts : {};
  //bannerurl;
  baseurl;
  bannerurl = Global.bannerurl;
 subcategoryids : {};
 //loader = true;
  loading = true;
  constructor(private api:getproductService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
  
    /* this.api.gettrendproductdata().subscribe((data)=>{
      
   
      this.products = data["Data"][0]["product"];
      //console.log(data["Data"][0]["product"]);

   }); */

   //this.href = this.router.url;
   //let URL=this.router.url.split('/');
   //console.log(this.route.parent)
   //this.bannerurl ="http://autoparts.sikho99.com/Project/resources/images/";
    this.baseurl="http://autoparts.sikho99.com";
    let URL;
    let URLElement;
    URL=this.router.url.split('/');
    URLElement=URL[2];  
      //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
      if (URLElement!=null) {
        //this.loader = true;
        this.api.getproductbysubcategory(URLElement).subscribe(
          data => {this.scproducts = data["Data"];
          this.loading =false;

          });
        
      } else {
        this.scproducts = null;
      }
      //this.loader = false;
  }


  onsubmit(data){
    console.log(data.productid);
  }
   
  onChangeCategory() {
    
  }

  

}
