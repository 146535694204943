import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
import { Customer } from './placeorder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

function _window() : any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class PlaceorderService {

  get nativeWindow() : any {
    return _window();
 }
 

  baseurl =Global.baseurl;
  datatest =new Subject<any>();

  sendclickevent(){
    this.datatest.next();
  }
  constructor(private http:HttpClient) { }

  postuser(CustomerId:string, user :Customer){
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerId", CustomerId);
     userData.append("AddressCompanyName", user.companyname);
     userData.append("AddressForName", user.fullname);
     userData.append("AddressPhoneNo", user.mobileno);
     userData.append("AddressEmailId", '');
     userData.append("AddressName", user.address);
     userData.append("AddressCityName", user.city);
     userData.append("AddressPincode", user.pincode);
     userData.append("AddressState", user.state);
     userData.append("AddressCountry", user.country);

    

     

    return this.http.post(`${this.baseurl}/addAddressForBuyPart`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  updateAddress (AddressId :string, user :Customer){
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("AddressId", AddressId );
     userData.append("AddressCompanyName", user.companyname);
     userData.append("AddressForName", user.fullname);
     userData.append("AddressPhoneNo", user.mobileno);
     userData.append("AddressEmailId", '');
     userData.append("AddressName", user.address);
     userData.append("AddressCityName", user.city);
     userData.append("AddressPincode", user.pincode);
     userData.append("AddressState", user.state);
     userData.append("AddressCountry", user.country);

    

     

    return this.http.post(`${this.baseurl}/updateAddress`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  getdefaultaddress(CustomerId: string){

    var defaultaddress: any = new FormData();
    defaultaddress.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getDefaultAddress`,defaultaddress);

  }

  getcarttotal(CustomerId: string){

    var carttotal: any = new FormData();
    carttotal.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCartTotalv2`,carttotal);

  }

   finalplaceorder(AddressId :string,CustomerId: string,OrderPaymentMethod :string){

    var carttotal: any = new FormData();
    carttotal.append("AddressId", AddressId);
    carttotal.append("CustomerId", CustomerId);
    carttotal.append("OrderPaymentMethod", OrderPaymentMethod);
    //carttotal.append("OrderTransactionNo", OrderTransactionNo);
    return  this.http.post(`${this.baseurl}/placeOrderForBuyPart`,carttotal);

  }

  getAddress(CustomerId: string){
    var cid: any = new FormData();
    cid.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getAddress`,cid);
  }


  updateDefaultAddress(CustomerId: string,AddressId:string){
    var updatedefadd: any = new FormData();
    updatedefadd.append("CustomerId", CustomerId);
    updatedefadd.append("AddressId", AddressId);
    return this.http.post(`${this.baseurl}/updateDefaultAddress`,updatedefadd);
  }
  
  getAddressByAddressId (AddressId: string){
    var cid: any = new FormData();
    cid.append("AddressId", AddressId);
    return this.http.post(`${this.baseurl}/getAddressByAddressId`,cid);
  }
  deleteAddress  (AddressId: string){
    var cid: any = new FormData();
    cid.append("AddressId", AddressId);
    return this.http.post(`${this.baseurl}/deleteAddress `,cid);
  }


}
