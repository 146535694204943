import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  images=["https://boodmo.com/media/images/slider/4c77cf0.jpg","https://boodmo.com/media/images/slider/0665377.jpg","https://boodmo.com/media/images/slider/15893d7.jpg"];
  constructor() { }

  ngOnInit(): void {
  }
  /* images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`); */
}
