import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
//import { parse } from 'path';
import { Global } from '../global-constant';
import { ProductdetailService } from './productdetail.service';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css']
})
export class ProductdetailComponent implements OnInit {
  //bannerurl: string;
  button;

  baseurl;
  pdetails;
  bannerurl = Global.bannerurl;
  username: string;
  uparid ;
  atcshow;
  atchide;
  iscart;
  aic;
  moq;
  public show:boolean = false;
  //atc;
  atc1;
  public atc:any =  `ADD  TO CART`;
 // public atcmoq:any = `ADD ${this.moq} TO CART`;
  cid;
  count: any;
  homepagebreadcrumb3: boolean;
  homepagebreadcrumb5: boolean;
  makerstypename: string;
  makersname: any;
  modelname: any;
  year: any;
  modificationname: any;
  constructor(private loginservice:LoginService,private api:ProductdetailService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    let data = localStorage.getItem('pdetailbreadcrumb');
   // alert(data)

    this.makerstypename = localStorage.getItem('1');;
      this.makersname =localStorage.getItem('2');;
      this.modelname =localStorage.getItem('3');;
      this.year =localStorage.getItem('4');;
      this.modificationname =localStorage.getItem('5');;
      
    //alert(this.year)
      
    if(this.year == 'undefined'){
     // alert("IF")
      this.homepagebreadcrumb3 = true;    
      this.homepagebreadcrumb5 = false;
    }
    else if(this.year == null){
      this.homepagebreadcrumb3 = false;    
      this.homepagebreadcrumb5 = false;
    }
    else if(this.year !=null || this.year !='undefined'){
     // alert("Else If")
      this.homepagebreadcrumb5 = true;
     // this.homepagebreadcrumb3 = false;
    }

    this.cid= sessionStorage.getItem('CustomerId');
    //this.bannerurl ="http://autoparts.sikho99.com/Project/resources/images/";
//this.baseurl="http://autoparts.sikho99.com";
    let URL;
    let URLElement;
    URL=this.router.url.split('/');
    URLElement=URL[2];  
    this.uparid = URL[2];
      //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
     // this.checkstock();
      if (URLElement!=null) {
        /* this.api.getpartByPartId(URLElement).subscribe(
          data => this.pdetails = data["Data"]
        ); */
        /* this.api.lateststockwithcartdata(URLElement,this.cid).subscribe(
          data => this.pdetails = data["Data"]
        ); */
        this.checkstock();

      } else {
        this.pdetails = null;
      }
      //this.checkstock();
      this.username= sessionStorage.getItem('CustomerName');
     console.log(this.username)

     /* if(this.checkstock()){
      this.moqinit();
     } */
    
  }
  /* moqinit(){
    alert(this.moq)
  } */
  /* toogle(pd){
    //alert("Hello")
    //this.moq = pd.PartMoq;
    //alert(this.moq)
     this.show = !this.show;
    //alert(this.show)
    // CHANGE THE NAME OF THE BUTTON.
    if(this.show){
      this.atc = "Already in Cart";
      this.addcart(pd);
    }  
    else{
      //alert("else")
      //this.atc = "Show";
      Swal.fire({
        title: 'Thanks',
        text: 'Product already in cart ',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Go to Cart',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/cart']);
        } else if (result.dismiss === Swal.DismissReason.close) {
        } 
      })
    }
      
  }  */

  /* tooglemoq(pd){
    //alert("Hello")
     this.show = !this.show;
    //alert(this.show)
    // CHANGE THE NAME OF THE BUTTON.
    if(this.show){
      this.atc1 = "Already in Cart";
      this.addcartmoq(pd);
    }  
    else{
      //alert("else")
      //this.atc = "Show";
      Swal.fire({
        title: 'Thanks',
        text: 'Product already in cart ',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Go to Cart',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/cart']);
        } else if (result.dismiss === Swal.DismissReason.close) {
        } 
      })
    }
      
  }  */

  toogle(element,pd){
    //alert(element);
    //this.show = !this.show;
   // CHANGE THE NAME OF THE BUTTON.
   //var partId=pd.PartId;
   //alert(partId)
   //alert(pd);
   this.button = element;
   if(this.button.textContent != "Added to cart"){     
   this.button.textContent = 'Added to cart'; 
    // this.atc = "Already in Cart";
     this.addcart(pd);
   }
   else{
    Swal.fire({
      title: 'Thanks',
      text: 'Product already in cart ',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Go to Cart',
      confirmButtonColor: '#FD8E01',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/cart']);
      } else if (result.dismiss === Swal.DismissReason.close) {
      } 
    })
   }
     
 } 
 tooglemoq(element,pd){
  //alert(element);
  //this.show = !this.show;
 // CHANGE THE NAME OF THE BUTTON.
 //var partId=pd.PartId;
 //alert(partId)
 //alert(pd);
 this.button = element;
 if(this.button.textContent != "Added to cart"){     
 this.button.textContent = 'Added to cart'; 
  // this.atc = "Already in Cart";
   this.addcartmoq(pd);
 }
 else{
  Swal.fire({
    title: 'Thanks',
    text: 'Product already in cart ',
    icon: 'success',
    //showCloseButton:true,
    showCloseButton:true,
   // showCancelButton: true,
    confirmButtonText: 'Go to Cart',
    confirmButtonColor: '#FD8E01',
  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigate(['/cart']);
    } else if (result.dismiss === Swal.DismissReason.close) {
    } 
  })
 }
   
} 
  

  /* inc(pd){
    console.log(pd); 
    //  pd.ProductQty=1;
   // console.log(pd)
    //console.log(pd.PartQty)
    //pd.ProductQty += 1;
    if(pd.PartQty !=20){
      pd.PartQty = parseInt(pd.PartQty)  +  1 ;
    }
    //pd.ProductQty = pd.ProductQty +  1 ;
  } */
  /* dec(pd){
    //pd.ProductQty += 1;
    if(pd.PartQty !=1){
      pd.PartQty = pd.PartQty -  1 ;
    } 
  } */
  itemsCart : any = [];

  

  addcart(category)
  {
      //this.uparid = category.PartId;
      //console.log(category);
      let cartDataNull = localStorage.getItem('localcart');
      if(cartDataNull == null){
        let storeDataGet:any  =[];
        storeDataGet.push(category);
        localStorage.setItem('localcart',JSON.stringify(storeDataGet));
      }
      else{
        var partId=category.PartId;
        var partQty = category.PartQty;
        let index:number = -1;
        this.itemsCart = JSON.parse(localStorage.getItem('localcart'));
        for(let i=0;i<this.itemsCart.length;i++){
          if(parseInt(partId) === parseInt(this.itemsCart[i].PartId)){
            this.itemsCart[i].PartQty = category.PartQty;
            index = i;
            break;

          }
        }
        if(index == -1){
          this.itemsCart.push(category);
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
        else{
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
      }

     // this.cartNumberFunc();
      
      //localStorage.setItem('localcart',JSON.stringify(category));
      var customerid = sessionStorage.getItem('CustomerId');
      var partId=category.PartId;
      //var partQty=category.PartQty;
      if(sessionStorage.getItem('CustomerId')){
        this.api.addtocartByProductDeatil(customerid,partId,1).subscribe(
          data => {
          //this.pdetails = data["Data"];
          if(data["Count"]==1){
            //  alert("hello");
              this.counter();
            }
          //alert("if 1")
          this.reloadComponent();

          //alert("if 2")
          //this.checkstock();
         
          });
      }
     
      else{
        
        this.router.navigate(['/login']);
      }
  }


  //piyush for more qnt in add to cart button 24/07/2021 
  addcartmoq(category){
    let cartDataNull = localStorage.getItem('localcart');
      if(cartDataNull == null){
        let storeDataGet:any  =[];
        storeDataGet.push(category);
        localStorage.setItem('localcart',JSON.stringify(storeDataGet));
      }
      else{
        var partId=category.PartId;
        var partQty = category.PartQty;
        let index:number = -1;
        this.itemsCart = JSON.parse(localStorage.getItem('localcart'));
        for(let i=0;i<this.itemsCart.length;i++){
          if(parseInt(partId) === parseInt(this.itemsCart[i].PartId)){
            this.itemsCart[i].PartQty = category.PartQty;
            index = i;
            break;

          }
        }
        if(index == -1){
          this.itemsCart.push(category);
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
        else{
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
      }

     // this.cartNumberFunc();
      
      //localStorage.setItem('localcart',JSON.stringify(category));
      var customerid = sessionStorage.getItem('CustomerId');
      var partId=category.PartId;
      var partMoq = category.PartMoq;
      //this.moq = category.PartMoq;
      //var partQty=category.PartQty;
      if(sessionStorage.getItem('CustomerId')){
        this.api.addtocartByProductDeatil(customerid,partId,partMoq).subscribe(
          data => {
            if(data["Count"]==1){
              //  alert("hello");
                this.counter();
              }
         // this.pdetails = data["Data"];
          //alert("if 1")
          this.reloadComponent();
          //alert("if 2")
          //this.checkstock();
          });
      }
      else{
        this.router.navigate(['/login']);
      }
  }
  counter(){
    //new
    var customerid = sessionStorage.getItem('CustomerId');
    this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
      this.count =data["Data"];
     //alert(this.count)
     console.log("count"+this.count)
     this.cartNumberFunc();
     })
 }
  showsweetalert(){
    Swal.fire({
      title: 'Thanks',
      text: 'Product already in cart ',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Go to Cart',
      confirmButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/cart']);
      
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }
  //piyush end
  checkstock(){

    var customerid = sessionStorage.getItem('CustomerId');
    console.log(customerid)
    //var a = this.uparid;
      //var partQty=category.PartQty;
      if(sessionStorage.getItem('CustomerId')){

        this.api.lateststockwithcartdata(this.uparid,customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            this.moq = data["Data"][0]["PartMoq"];
            this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);
            //alert(this.moq)
            
            /* if(this.moq!=null){
              //alert(this.moq)
              
            } */
          //  this.atc1 = `ADD ${this.moq} TO CART`;
             if(this.iscart ==true){
            //   alert("Hello")
              this.atcshow = false;
             // this.aic = true;
  
              console.log("Product in already cart")
            }
            else if(this.iscart ==false){
              this.atcshow = true;
             // this.aic = false;
              console.log("Product in not already cart ")
            } 

          
          });
      }
      else{
       /*  if(customerid == null){
          alert(customerid)
        } */
        this.api.lateststockwithcartdata(this.uparid,customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            this.iscart = data["Data"][0]["isCart"]
            console.log(data["Data"][0]["isCart"]);

            if(this.iscart ==false){
              this.atcshow = true;
              console.log("Product in not already cart ")
            } 

          
          });
       // this.router.navigate(['/login']);
      }
  }

  cartNumber :number =0;
  cartNumberFunc(){
    var cartValue =JSON.parse(localStorage.getItem('localcart'));
   // this.cartNumber =cartValue.length;
    this.cartNumber = this.count;
    this.api.cartSubject.next(this.cartNumber);
    console.log(this.cartNumber);
  }


  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
 

}
