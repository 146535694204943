import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../global-constant';
import { BrandscatlogService } from './brandscatlog.service';

@Component({
  selector: 'app-brandscatlog',
  templateUrl: './brandscatlog.component.html',
  styleUrls: ['./brandscatlog.component.css']
})
export class BrandscatlogComponent implements OnInit {

  bannerurl = Global.bannerurl;
  parts :{};
  constructor(private api:BrandscatlogService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    /* let URL;
    let URLElement;
    URL=this.router.url.split('/');
    URLElement=URL[2];    
    console.log(URLElement);
      if (URLElement!=null) {      
        this.api.getproductbybrand(URLElement).subscribe(
          data => {this.parts = data["Data"];
          //this.loading =false;
          console.log(this.parts);

          });
        
      } else {
        this.parts = null;
      } */

      let URL;
    let URLElement;
    URL=this.router.url.split('/');
    URLElement=URL[2];  
      //console.log( localStorage.getItem('subcategoryid'));
    console.log(URLElement);
      if (URLElement!=null) {
        //this.loader = true;
        this.api.getproductbybrand(URLElement).subscribe(
          data => {this.parts = data["Data"];
         // this.loading =false;
          console.log(this.parts);

          });
        
      } else {
        this.parts = null;
      }
  }

}
