import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorypage',
  templateUrl: './categorypage.component.html',
  styleUrls: ['./categorypage.component.css']
})
export class CategorypageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
