import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brandpage',
  templateUrl: './brandpage.component.html',
  styleUrls: ['./brandpage.component.css']
})
export class BrandpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
