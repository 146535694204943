import { Component, OnInit } from '@angular/core';
import { Global } from '../global-constant';
import { OffersService } from './offers.service';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {


  bannerurl = Global.bannerurl;
  offers :{};
  constructor(private api:OffersService) { }

  ngOnInit(): void {
    this.api.getalloffers().subscribe((data)=>{
      this.offers = data["Data"]

      /* if(data!=null)
      {
        this.offers = data["Data"];
      }
      else{
        
        console.log("NO DATA FOUND")
      }*/
  }) 
  }

}
