import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';



import {UseapiService} from './useapi.service';
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

// interface Banners{
//     BannerId : String;
//     BannerImage : String;
// }

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
     bannerimage ="123";
     //banners : Banners[];
     banners;
     private _router: Subscription;

     
    /* count=0;
    isDisable = false; */
    constructor( private api:UseapiService, private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location) {}
    @HostListener('window:scroll', ['$event'])

    

    hasScrolled() {

        var st = window.pageYOffset;
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        var navbar = document.getElementsByTagName('nav')[0];

        // If they scrolled down and are past the navbar, add class .headroom--unpinned.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            if (navbar.classList.contains('headroom--pinned')) {
                navbar.classList.remove('headroom--pinned');
                navbar.classList.add('headroom--unpinned');
            }
            // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
        } else {
            // Scroll Up
            //  $(window).height()
            if(st + window.innerHeight < document.body.scrollHeight) {
                // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
                if (navbar.classList.contains('headroom--unpinned')) {
                    navbar.classList.remove('headroom--unpinned');
                    navbar.classList.add('headroom--pinned');
                }
            }
        }

        lastScrollTop = st;
    };
    ngOnInit() {

         

      var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          if (window.outerWidth > 991) {
              window.document.children[0].scrollTop = 0;
          }else{
              window.document.activeElement.scrollTop = 0;
          }
          this.renderer.listen('window', 'scroll', (event) => {
              const number = window.scrollY;
              if (number > 150 || window.pageYOffset > 150) {
                  // add logic
                  navbar.classList.add('headroom--not-top');
              } else {
                  // remove logic
                  navbar.classList.remove('headroom--not-top');
              }
          });
      });
      this.hasScrolled();


        // this.api.getapidata().subscribe( (data) =>{
        // console.warn("get api data ",data);
        // this.bannerimage = data['Data'][0]['BannerImage'];
        // })
        

        /* this.api.getapidata().subscribe((bannersdata) =>{
            

                this.banners= bannersdata["Data"];
             //   this.data = response["Data"];
              //  console.log(banners["Data"]);
            
            //console.warn("get api data ",data["Data"]);
            //this.students.push (data["Data"]);
           // this.bannerimage = data['Data'][0]['BannerImage'];
            //this.bannerimage =data["Data"][0]["BannerImage"];
            //this.bannerimage =data["Data"].map();
            //this.bannerimage = object.array.map( (o)=> o.property );

        })  */
        
            
    }
    /* counter(){
        this.count++;
        this.count === 10 ? this.isDisable = true : this.isDisable=false;
    }
    reset(){
        this.count =0;
        this.isDisable = false;
    } */
}
