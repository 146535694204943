import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
/* import {CategoriesComponent} from '../categories/categories.component'; */

import { SectionsModule } from '../sections/sections.module';
import {CategoriesModule} from '../categories/categories.module';
import { SearchcategoryModule } from '../searchbycategory/searchcategory.module';
 import { OffersModule } from '../offers/offers.module';
import { BrandsModule } from '../brands/brands.module';
import { AppModule } from '../app.module';
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
         CategoriesModule ,
         SearchcategoryModule,
         OffersModule ,
         BrandsModule,
        // AppModule
         
    ],
    declarations: [ HomeComponent ],
    exports:[ HomeComponent ],
    providers: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HomeModule { }
