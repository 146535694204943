import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Global} from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  baseurl =Global.baseurl;
  //cartS`ubject = new Subject<any>();
  constructor(private http:HttpClient) { }

  addContactUs(ContactusName: string,ContactusEmailId  :string,ContactusComment:string){
    var filterscid: any = new FormData();
    filterscid.append("ContactusName", ContactusName );
    filterscid.append("ContactusEmailId", ContactusEmailId );
    filterscid.append("ContactusComment", ContactusComment );
    return this.http.post(`${this.baseurl}/addContactUs`,filterscid);
  }

}
