import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { Router, NavigationEnd, NavigationStart ,ActivatedRoute} from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { bannerapiService } from './bannerapi.service';
import { Global } from '../global-constant';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { ProductdetailService } from '../productdetail/productdetail.service';
import { threadId } from 'worker_threads';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})



export class HomeComponent implements OnInit {

   
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    banners;
    //bannerurl;
    categorys : {};
    subcategorys : {};
    subcategoryid :{};
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    bannerurl = Global.bannerurl;

    carmakers:{};
    models:{};
    modifications:{};
    mtypes:{};
    makers:{};
    images=["https://boodmo.com/media/images/slider/4c77cf0.jpg","https://boodmo.com/media/images/slider/0665377.jpg","https://boodmo.com/media/images/slider/15893d7.jpg"];
  count: any;
  modificationid: any;
  test: string;
  onchangeyear: any;
    constructor(private api1:ProductdetailService,private loginservice:LoginService, private api:bannerapiService, public location: Location, private router: Router,private route: ActivatedRoute) {
    }

    ngOnInit() {
      //localStorage.clear();

      let  customerId;
      this.route.queryParams.subscribe(params =>{
       customerId = params["customerId"];
      })
      if(customerId!=null){
      //  alert(customerId)
       // alert(customerId)
        this.api.getCustomerDataById(customerId).subscribe((data)=>{
          let CustomerName = data["Data"][0]["CustomerName"];
          sessionStorage.setItem('CustomerName',CustomerName);
          
          
        })
       // alert("hello")
        sessionStorage.setItem('CustomerId',customerId);
        localStorage.clear();
        this.router.navigate(['/home']).then(() => {
          /* window.location.reload(); */
          this.showsweetalert();
         
        });
        
     // alert("hello 2")

      }
      var customerid = sessionStorage.getItem('CustomerId');
      this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
        this.count =data["Data"];
       //alert(this.count)
       console.log("count"+this.count)
       this.cartNumberFunc();
       })
     // alert("hello 3")

        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
          if (event instanceof NavigationStart) {
             if (event.url != this.lastPoppedUrl)
                 this.yScrollStack.push(window.scrollY);
         } else if (event instanceof NavigationEnd) {
             if (event.url == this.lastPoppedUrl) {
                 this.lastPoppedUrl = undefined;
                 window.scrollTo(0, this.yScrollStack.pop());
             } else
                 window.scrollTo(0, 0);
         }
       });
       this.location.subscribe((ev:PopStateEvent) => {
           this.lastPoppedUrl = ev.url;
       });
  
       this.api.getbannerdata().subscribe((bannersdata) =>{
          
         // this.bannerurl ="http://autoparts.sikho99.com/Project/resources/images/";
        
          
          this.banners= bannersdata["Data"][0]["Banner"];
       //   this.data = response["Data"];
          //console.log(bannersdata["Data"][0]["Banner"]);
      
      
  
          });
        /* this.api.getformdata().subscribe((categorydata)=>{
            this.categorys = categorydata["Data"];
        }) */  

        
         let name = sessionStorage.getItem('CustomerName');
         console.log(name);
        // alert(name)  
         /* if(name!=null)
         {
          this.router.navigate(['/home']);
          
         } */
         

         
      // console.log(this.bannerurl);
      // console.log(Global.bannerurl);

      this.api.getcarmakerstypedata().subscribe((data)=>{
        this.mtypes = data["Data"];
      })


      }
      cartNumber :number =0;
      cartNumberFunc(){
        var cartValue =JSON.parse(localStorage.getItem('localcart'));
        //this.cartNumber =cartValue.length;
        this.cartNumber =this.count;
       // alert(this.count)
        
        this.api1.cartSubject.next(this.cartNumber);
        console.log(this.cartNumber);
      }

      
      onChangemakersType(MakerstypeId: number) {
        //console.log(StateId);
        if (MakerstypeId!=null) {
          this.api.getmakersdata(MakerstypeId).subscribe(
            data => this.makers = data["Data"]
          );
        } else {
          this.makers = null;
        }
      }

  
      onChangemakers(makersid: number) {
        if (makersid!=null) {
          this.api.getmodeldata(makersid).subscribe(
            data => this.models = data["Data"]
          );
        } else {
          this.models = null;
        }
      }
      

      onChangeModel(modelid: number,ondata) {
        //console.log(StateId);
        this.modificationid = '';
    //    alert(ondata.year)
       this.test = ondata.year = '';
      //  alert("onchange"+this.test)
       // alert(this.modificationid   )
        if (modelid!=null) {
          this.api.getmodificationdata(modelid).subscribe(
            data2 => this.modifications = data2["Data"]
          );
        } else {
          this.modifications = null;
        }
      }
      onChangeYear(Year :number){
        this.onchangeyear = Year;
      // alert(this.onchangeyear)
      }
      /* onChangeCategory(CategoryId: number) {
        //console.log(StateId);
        if (CategoryId!=null) {
          this.api.getsubcategorydata(CategoryId).subscribe(
            data => this.subcategorys = data["Data"]
          );
        } else {
          this.subcategorys = null;
        }
      } */
  
      isHome() {
          var titlee = this.location.prepareExternalUrl(this.location.path());
  
          if( titlee === '#/home' ) {
              return true;
          }
          else {
              return false;
          }
      }
      isDocumentation() {
          var titlee = this.location.prepareExternalUrl(this.location.path());
          if( titlee === '#/documentation' ) {
              return true;
          }
          else {
              return false;
          }
      }

      /*getproduct($myParam: string = ''): void {
        const navigationDetails: string[] = ['/products'];
        if($myParam.length) {
          navigationDetails.push($myParam);
        }
        this.router.navigate(navigationDetails);
      } */
      /* onSubmit(form:NgForm){
        this.loginservice.postuser(form.value).subscribe(
            res =>{ 
                this.ShowSuccessmessage = true;
                setTimeout(() => this.ShowSuccessmessage = false ,3000);
                //sessionStorage.setItem('CustomerPhoneNo','login.mobileno');
                this.resetForm(form);
            },);
        } */  
      onClickSubmit(data2){

        this.modificationid = data2.year;
       // alert(this.modificationid)
      //  alert("test"+this.test)
        if(this.test ==''){
        //  alert("submit")

          this.router.navigate(['/vehicle',data2.model,this.test]);
        }
        if(this.onchangeyear !=null){
        //  alert(this.onchangeyear)
 //         alert("hello")
          this.router.navigate(['/vehicle',data2.model,this.onchangeyear]);
        }

     //   this.onChangeModel(this.modificationid);
       // console.log( data2.year);
       // console.log("Model Id"+data2.model)
       // localStorage.setItem('ModificationId','data2.year');
        //localStorage.setItem('ModelId','data2.model');
       // this.router.navigate(['/vehicle',data2.model,this.modificationid]);
      }
      showsweetalert(){
        Swal.fire({
          title: 'Thanks',
          text: 'Thank You Place order Sucessfully ',
          icon: 'success',
          //showCloseButton:true,
          showCloseButton:true,
         // showCancelButton: true,
          confirmButtonText: 'OK',
          confirmButtonColor: '#FD8E01',
          
        }).then((result) => {
          if (result.isConfirmed) {
           // this.router.navigate(['/cart']);
        //   window.location.reload();
          
          } else if (result.dismiss === Swal.DismissReason.close) {
            
          }
          
        })
      }
  
}
