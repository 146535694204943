import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from './cart.service';
import { ProductdetailService } from '../productdetail/productdetail.service';
import { Global } from '../global-constant';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  pdetails;
  cartid;
  ucartqnt;
  updatecart;
  total:number =0;
  discount:number = 0;
  pmrp: any;
  PartMrp: any;
  PartMoq: any;
  PartLessMoqDiscount: any;
  PartMoreMoqDiscount: any;
  CartQuantity: any;
  finalmrp: any;
  //mrparray:string[];
  buttonDisabled: boolean;
  msg;
  baseurl =Global.baseurl;
  count: any;
  subtotal: number;
  discountamt: number;
  constructor(private loginservice:LoginService,private api:CartService,private api1:ProductdetailService, private router:Router) { }

  ngOnInit(): void {
  //  this.buttonDisabled = false;
  
    /* var customerid = sessionStorage.getItem('CustomerId');
      if(sessionStorage.getItem('CustomerId')){
        this.api.cartDetails(customerid).subscribe(
          data => {
            this.pdetails = data["Data"];
            //this.cartid = data["Data"][0["CartId"]]
            console.log(data["Data"]);
            this.testing();
            //this.reloadComponent();
            //console.log(this.cartid)
          });
      }
      else{
        this.router.navigate(['/login']);
      } */

     // this.loadcart();
      //this.removecart(this.pdetails);
      this.cartDetails();
     this.testing();
      this.counter();
     // this.testing();
  }
  //parseInt(pd.PartQty)

    
  inc(PartId,qnt){
    for(let i=0;i<this.pdetails.length;i++){
      if(this.pdetails[i].PartId == PartId){

        this.pdetails[i].CartQuantity = parseInt(qnt) + 1;
        //console.log(this.pdetails[i].CartQuantity);
        //console.log(this.pdetails[i].CartId);

        this.cartid = this.pdetails[i].CartId;
        this.ucartqnt = this.pdetails[i].CartQuantity;

        this.api.updatecartqnt(this.cartid,this.ucartqnt).subscribe((data1)=>{  
           this.updatecart =  data1["Data"];
            //alert(this.CartQuantity)
            //alert(this.ucartqnt)
           /* if( this.ucartqnt < this.PartMoq){
           // alert(this.ucartqnt)      
            this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartLessMoqDiscount)/100);
            //alert(this.finalmrp)
            if(this.PartMoq == this.ucartqnt){
              //alert("Equal")
              //this.reloadComponent();
              
            }
            
            //this.testing();
          }
          else if(this.ucartqnt >= this.PartMoq)
          {
            this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartMoreMoqDiscount)/100);
            //this.reloadComponent();
            if(this.PartMoq == this.ucartqnt){
             // this.reloadComponent();
            }
            //this.testing();
          } */

           this.testing();
               //kd 
               this.cartDetails();
       
        })

        
        
        
      }      
    }   
    //localStorage.setItem('localcart',JSON.stringify(this.pdetails));
    //this.loadcart();
  }
  
  dec(PartId,qnt){
    for(let i=0;i<this.pdetails.length;i++){
      if(this.pdetails[i].PartId == PartId){
        if(qnt != 1){
          this.pdetails[i].CartQuantity = parseInt(qnt) - 1;

        }   

        this.cartid = this.pdetails[i].CartId;
        this.ucartqnt = this.pdetails[i].CartQuantity;

        this.api.updatecartqnt(this.cartid,this.ucartqnt).subscribe((data1)=>{
           this.updatecart =  data1["Data"];

           /* if( this.ucartqnt < this.PartMoq){
            // alert(this.ucartqnt)      
             this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartLessMoqDiscount)/100);
             //alert(this.finalmrp)
             if(this.PartMoq == this.ucartqnt){
               //this.reloadComponent();
             }
             
             //this.testing();
           }
           else if(this.ucartqnt >= this.PartMoq)
           {
             this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartMoreMoqDiscount)/100);
             //this.reloadComponent();
             if(this.PartMoq == this.ucartqnt){
               //this.reloadComponent();
             }
             //this.testing();
           } */
           this.testing();
           //kd 
           this.cartDetails();
        })

        
      }
    }
    //console.log("hello" +this.updatecart)
    
    //localStorage.setItem('localcart',JSON.stringify(this.pdetails));
    //this.loadcart();
  }

  testing(){
    console.log("Testing Function ")
    var customerid = sessionStorage.getItem('CustomerId');
    if(sessionStorage.getItem('CustomerId')){
      this.api.getcarttotal(customerid).subscribe(
        data1 => {
          this.total = data1["Data"][0]["SubTotal"];
          this.discount = data1["Data"][0]["Save"];
          //console.log
         // console.log(data1["Data"][0]["SubTotal"]);
        });
      //console.log(this.total)
      
      //[0]["SubTotal"]
    }
    else{
      this.router.navigate(['/login']);
    }


  }
  updateqnt(PartId,qnt,iqnt:string){
    console.log("hello" + qnt )
    //console.log(event.target.value);
    console.log(iqnt)
    console.log(PartId)
     for(let i=0;i<this.pdetails.length;i++){
      if(this.pdetails[i].PartId == PartId){
        
          this.pdetails[i].CartQuantity = parseInt(iqnt);

        this.cartid = this.pdetails[i].CartId;
        this.ucartqnt = this.pdetails[i].CartQuantity;

          this.api.updatecartqnt(this.cartid,this.ucartqnt).subscribe((data1)=>{
           this.updatecart =  data1["Data"];

           /* if( this.ucartqnt < this.PartMoq){
            // alert(this.ucartqnt)      
             this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartLessMoqDiscount)/100);
             //alert(this.finalmrp)
             if(this.PartMoq == this.ucartqnt){
               //this.reloadComponent();
             }
             
             //this.testing();
           }
           else if(this.ucartqnt >= this.PartMoq)
           {
             this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartMoreMoqDiscount)/100);
             //this.reloadComponent();
             if(this.PartMoq == this.ucartqnt){
               //this.reloadComponent();
             }
             //this.testing();
           } */
           
           this.testing();
               //kd 
               this.cartDetails();
       
        })

         
         
      

        }
        
    } 
  }

//total:number =0;
//loadcart(){
   /* if(localStorage.getItem('localcart')){
     this.pdetails = JSON.parse(localStorage.getItem('localcart'));
     this.total = this.pdetails.reduce(function(acc,val){
      return acc + (val.PartSrp * val.PartQty);  
    },0); 
   } */

   /*  this.total = this.pdetails.reduce(function(acc,val){
    return acc + (val.PartSrp * val.CartQuantity);  
  }); */ 
    
//}

cartDetails(){

  /* var customerid = sessionStorage.getItem('CustomerId');
        this.api.cartDetails(customerid).subscribe(
          data => {this.pdetails = data["Data"];
          console.log(this.pdetails)
            this.PartMrp = data["Data"][0]["PartMrp"];
            this.PartMoq = data["Data"][0]["PartMoq"];
            this.PartLessMoqDiscount = data["Data"][0]["PartLessMoqDiscount"];
            this.PartMoreMoqDiscount = data["Data"][0]["PartMoreMoqDiscount"];
            console.log(this.PartMrp)
          }); */
//19/07/2021 
          var flag=0;

          var customerid = sessionStorage.getItem('CustomerId');
          if(sessionStorage.getItem('CustomerId')){
            this.api.cartDetails(customerid).subscribe(
              data => {
                this.pdetails = data["Data"];   
                if(this.pdetails == ''){
                  this.buttonDisabled =false;
                  this.msg = true;
                }
               // this.count++;
               // alert(this.pdetails.length)
               /* for(let i=0;i<this.pdetails;i++){
                 this.count++;
                
               } */
               // this.msg =true;
                this.CartQuantity = data["Data"][0]["CartQuantity"];
                //alert(this.CartQuantity)
                this.PartMrp = data["Data"][0]["PartMrp"];
                this.PartMoq = data["Data"][0]["PartMoq"];
                this.PartLessMoqDiscount = data["Data"][0]["PartLessMoqDiscount"];
                this.PartMoreMoqDiscount = data["Data"][0]["PartMoreMoqDiscount"];
                //console.log(this.PartMrp);
                //console.log(data["Data"]);  
                //var mrparray =[];
                Number('this.CartQuantity');
                Number('this.PartMoq');
               
                for(let i=0;i<this.pdetails.length;i++){
                  
                  
                  //kd start for  dynamic  MOQ calculation
                  if(Number(data["Data"][i]["CartQuantity"])>=Number(data["Data"][i]["PartMoq"]))
                  {
                    //alert("disount 20")
                    data["Data"][i]["Discount"] = Number(data["Data"][i]["PartMrp"] - (data["Data"][i]["PartMrp"] * data["Data"][i]["PartMoreMoqDiscount"]/100));
                    
                  }
                  else{
                    //alert("discount 10")
                    data["Data"][i]["Discount"] = Number(data["Data"][i]["PartMrp"] - (data["Data"][i]["PartMrp"] * data["Data"][i]["PartLessMoqDiscount"]/100));
                    
                  }
                  //else{
                //    alert(Math.round( data["Data"][i]["Discount"]))
                this.discountamt = Math.round( data["Data"][i]["Discount"]);

                data["Data"][i]["SUbTotal"] = Math.round( data["Data"][i]["Discount"]) * data["Data"][i]["CartQuantity"];
                
                /* this.subtotal = discount *  this.CartQuantity
                 alert(this.subtotal) */
                // alert(Math.round(1.8))
                  //}
                  //this.mrparray.push("1");
                  //data["Data"][i]["Test"]="Hello";
                 // mrparray.push(this.finalmrp);
                  //console.log(this.pdetails.finalmrp);
                  //this.testing();
                }
                /* if( this.CartQuantity < this.PartMoq){
                  // alert(this.ucartqnt)      
                   this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartLessMoqDiscount)/100);
                   //alert(this.finalmrp)
                   if(this.PartMoq == this.CartQuantity){
                     //this.reloadComponent();
                   }
                   
                   //this.testing();
                 }
                 else if(this.CartQuantity >= this.PartMoq)
                 {
                   this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartMoreMoqDiscount)/100);
                   //this.reloadComponent();
                   if(this.PartMoq == this.CartQuantity){
                     //this.reloadComponent();
                   }
                   //this.testing();
                 } */
               // console.log("my final mrp"+this.finalmrp)
               // console.log("part mrp "+this.PartMrp)
                //console.log(this.mrparray)
                //console.log(mrparray);
                //console.log(mrparray.split(','));
                //console.log(this.finalmrp)
                /* if(this.CartQuantity < this.PartMoq){
                  
                  this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartLessMoqDiscount)/100);
                  this.testing();
                }
                if(this.CartQuantity >= this.PartMoq)
                {
                  this.finalmrp = (this.PartMrp - (this.PartMrp * this.PartMoreMoqDiscount)/100);
                  this.testing();
                } */
                
                
              });
          }
          else{
            this.router.navigate(['/login']);
          }
}
/* counter(i: number) {
  return i++;
} */
removecart(pdetails){
  var cartId = pdetails.CartId;
  let rcart;
  console.log(cartId)
   this.api.removecart(cartId).subscribe(
    data => {
      rcart = data["Data"];
    this.testing();
    this.cartDetails();
      this.counter();
    //delete from local 
    /* let results:any = pdetails.slice(cartId);
    localStorage.setItem('localcart',JSON.stringify(results));
    pdetails =results; */

    //localStorage.removeItem('localcart');
    

    //delete from local 
    var pid = pdetails.PartId;
    console.log(pid)
    /* if(localStorage.getItem('localcart')){
      this.pdetails = JSON.parse(localStorage.getItem('localcart'));
      for(let i=0;i<this.pdetails.length;i++){
        if(this.pdetails[i].PartId === pid){
          this.pdetails.splice(i,1);
          localStorage.setItem('localcart',JSON.stringify(this.pdetails));
         // this.loadcart();
          this.cartNumberFunc();

        }
      }
    } */

  });  
}
counter(){
     //new
     var customerid = sessionStorage.getItem('CustomerId');
     this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
       this.count =data["Data"];
      //alert(this.count)
      console.log("count"+this.count)
      this.cartNumberFunc();
      })
  }
reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }
  refresh(): void {
    window.location.reload();
}

cartNumber :number =0;
  cartNumberFunc(){
    var cartValue =JSON.parse(localStorage.getItem('localcart'));
   // this.cartNumber =cartValue.length;
    this.cartNumber =this.count;
    this.api1.cartSubject.next(this.cartNumber);
    console.log(this.cartNumber);
  }

placeorder(){
  this.router.navigate(['/placeorder']);
 
  

}

}
