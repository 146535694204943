import { Component, NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { AboutComponent } from './about/about.component';
import { CategoriesComponent } from './categories/categories.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ProductsComponent } from './products/products.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { SearchingComponent } from './searching/searching.component';
import { CartComponent } from './cart/cart.component';
import { SearchbycategoryComponent } from './searchbycategory/searchbycategory.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { OffersComponent } from './offers/offers.component';
import { BrandsComponent } from './brands/brands.component';
import { CatlogComponent } from './catlog/catlog.component';
import { BrandscatlogComponent } from './brandscatlog/brandscatlog.component';
import { ModalexampleComponent } from './modalexample/modalexample.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { MyorderComponent } from './myorder/myorder.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { PaymentComponent } from './payment/payment.component';
import { BrandpageComponent } from './brandpage/brandpage.component';
import { CategorypageComponent } from './categorypage/categorypage.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { AddressComponent } from './address/address.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ReturnorderComponent } from './returnorder/returnorder.component';
import { BillprintComponent } from './billprint/billprint.component';
const routes: Routes =[
    { path: 'home',           component: HomeComponent },
    { path: 'user-profile',   component: ProfileComponent },
    { path: 'register',       component: SignupComponent },
    { path: 'landing',        component: LandingComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'about',          component: AboutComponent },
    { path: 'products',       component: ProductsComponent },
    { path: 'productdetail/:ProductSlug',  component: ProductdetailComponent },

    { path: 'productdetail',  component: ProductdetailComponent },
    { path: 'categories',          component: CategoriesComponent },
    { path: 'contactus',           component: ContactusComponent },
    {path: 'products/:SubcategoryId' , component : ProductsComponent},
    {path: 'searching' ,component : SearchingComponent },
    {path:'cart',component:CartComponent},
    {path:'offer',component:OffersComponent},
    {path:'searchbycategory',component:SearchbycategoryComponent},
    {path:'vehicle',component:VehicleComponent},
    {path:'vehicle/:ModificationId',component:VehicleComponent},
    {path:'vehicle/:ModelId/:ModificationId',component:VehicleComponent},
    {path:'brands',component:BrandsComponent},
    {path:'catlog',component:CatlogComponent},
    {path: 'catlog/:CategoryId' , component : CatlogComponent},
    {path: 'catlog/:CategoryId/:ModificationModelNo' , component : CatlogComponent},
    //for filter 
    {path: 'catlog/:CategoryId/:ModificationModelNo/:ModificationModelNo3/:ModificationModelNo5' , component : CatlogComponent},
   /*  {path: 'catlog/?cat_id=/:CategoryId' , component : CatlogComponent},
 */
    {path: 'brandscatlog' , component : BrandscatlogComponent},
    {path: 'brandscatlog/:BrandId' , component : BrandscatlogComponent},
    {path: 'modal',component:ModalexampleComponent},
    {path : 'placeorder',component:PlaceorderComponent}, 
    {path:'myorder',component:MyorderComponent},
    {path:'orderdetail/:orderid',component:OrderdetailComponent},
    {path:'orderdetail/:orderid/:fail',component:OrderdetailComponent},
    {path:'payment',component:PaymentComponent},
    {path:'brand',component:BrandpageComponent},
    {path:'category',component:CategorypageComponent},
    {path:'privacypolicy',component:PrivacypolicyComponent},
    {path:'terms-condition',component:TermsconditionsComponent},
    {path:'return-policy',component:ReturnpolicyComponent},
    {path:'address',component:AddressComponent},
    {path:'bread',component:BreadcrumbComponent},
    {path:'returnorder/:orderid',component:ReturnorderComponent},
    {path:'billprint/:orderid',component:BillprintComponent},
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

// export const routingcomponent= [ProductsComponent] 
