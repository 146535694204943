import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { empty } from 'rxjs';
import Swal from 'sweetalert2';
import { Global } from '../global-constant';
import { UserService } from '../signup/user.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  closeResult = '';
  getotpform: FormGroup;
  @ViewChild('otpmodal') otpmodal;
  verifyotp: any;
  mobileno: any;
  constructor(private modalService: NgbModal,private userService:UserService,private  formbuilder:FormBuilder,private router:Router) { }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.setFormStateotpverification();
  }

  //for otp 17/9/21
  setFormStateotpverification(): void{
    //console.log(this.defaddress)
    this.getotpform = this.formbuilder.group({
      otp : ['',[Validators.required,Validators.minLength(6)]],
      /* confirmnewpassword : ['',[Validators.required,]], */
      
    })
  }
  onsubmitotp(){
   // alert("hello")
    let otp =this.getotpform.value.otp;
   // alert(this.getotpform.value.otp);
    // this.userService.verifyOTP(otp,this.customerid).subscribe((res)=>{
    //   this.verifyotp =res["Data"];
    //   if(this.verifyotp == 1){
        
    //     /* Swal.fire({
    //             title: 'Thanks',
    //             text: 'Your OTP verification is Sucess',
    //             icon: 'success',
    //             //showCancelButton: true,
    //             confirmButtonText: 'Login',
                
    //           })
    //           this.router.navigate(['/login']); */

    //           Swal.fire({
    //             title: 'Thanks',
    //             text: 'Your OTP verification is Sucess',
    //             icon: 'success',
    //             //showCloseButton:true,
    //             showCloseButton:true,
    //            // showCancelButton: true,
    //             confirmButtonText: 'Login',
                
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //             //  alert("hello")
    //              /*  this.otpmodal.nativeElement.close();
    //                this.getotpform.disable();
    //              this.otpmodal.close();
    //              this.otpmodal.dismiss();
                 
    //              this.otpmodal.hide(); */
    //             // this.reloadComponent();
    //               /* this.router.navigate(['/login']);
    //               this.refresh(); */

    //               this.router.navigate(['/login'])
    //               .then(() => {
    //                    window.location.reload();
    //                });                    
                 
                
    //             } else if (result.dismiss === Swal.DismissReason.close) {
                  
    //             }
                
    //           })
        
    //   }
    //   else{
    //     Swal.fire('Oops...', 'OTP verification failed Try Again :)', 'error')
    //   }
    // })


    /* this.submitted =true;
    // alert(this.changepassword.value.newpassword);
    if(this.changepassword.value.newpassword== this.changepassword.value.confirmnewpassword){
      let password = this.changepassword.value;
      this.changepass(password);
      return;
    }
    else{
      let password = this.changepassword.value;
     // this.changepass(password);
    } */
  }
  /* resendotpapi(){
    this.userService.reSendOTPUsingSms(this.mobileno,this.customerid).subscribe((res)=>{
      let resend = res["Data"];
      if(resend == 1){
        Swal.fire({
          title: 'Thanks',
          text: 'OTP resend Sucessfully',
          icon: 'success',
          //showCloseButton:true,
          showCloseButton:true,
         // showCancelButton: true,
          confirmButtonText: 'OK',
          
        }).then((result) => {
          if (result.isConfirmed) {
            //this.router.navigate(['/login']);
          
          } else if (result.dismiss === Swal.DismissReason.close) {
            
          }
          
        })

      }
    })
  } */

  refresh(): void {
    window.location.reload();
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

}
