export class Global{
    //old baseurl
    //public static baseurl: string ="https://gomechanic.idnmserver.com/Admin/Ajax";

    //new baseurl
    //public static baseurl: string ="http://gomechanic2.idnmserver.com/Admin/Ajax";
 //   public static baseurl: string ="https://buypartsonline.idnmserver.com/Admin/Ajax";
    //new
    public static baseurl: string ="https://buypartsonline.in/AdminSide/Admin/Ajax";
   /*  http://buypartsonline.in/AdminSide/Admin/Ajax */

    //public static bannerurl: string ="http://autoparts.sikho99.com/Project/resources/images/";
    //public static bannerurl: string ="https://gomechanic2.idnmserver.com/resources/images/";
 //   public static bannerurl: string ="https://buypartsonline.idnmserver.com/resources/images/";

    public static bannerurl: string ="https://buypartsonline.in/AdminSide/resources/images/";

    
}