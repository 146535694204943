import { Component, OnInit } from '@angular/core';

import { ContactService } from './contact.service';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  contactusform:FormGroup;

  constructor(private api:ContactService,private  formbuilder:FormBuilder) { }

  ngOnInit(): void {
    this.setFormState();

  }
  setFormState(): void{
    //console.log(this.defaddress)
    this.contactusform = this.formbuilder.group({
 
      email : ['',[Validators.required]],
      name : ['',[Validators.required]],
      description : ['',[]],
    })
  }
  onSubmitcontactus(){
    /* let billform = this.contactusform.value;
    alert(billform) */
  //  alert(this.contactusform.value.email)
    let email =this.contactusform.value.email;
    let name = this.contactusform.value.name;
    let description = this.contactusform.value.description;

    this.api.addContactUs(name,email,description).subscribe((res)=>{

      let data=res["Data"];   
              console.log(data)
              if(data){
                this.showsweetalert();
              // alert("sucess")
              }
              else{
                /* this.Error =res["IsSuccess"];
                this.message =res["Message"];
                setTimeout(() => this.Error = false  ,6000); */
  
                
              
              //  this.serverErrorMessage= true;
              } 
    })
  }


  showsweetalert(){
    Swal.fire({
      title: 'Thanks',
      text: 'Your Valuable Feedback Send Sucessfully ',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
       // this.router.navigate(['/cart']);
      this.refresh();
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }
  refresh(): void {
    window.location.reload();
}

}
