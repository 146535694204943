import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgForm} from '@angular/forms';
import { LoginService } from './login.service'; 

import { Router } from '@angular/router';
import {User} from './login';
import { empty } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { UserService } from '../signup/user.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ProductdetailService } from '../productdetail/productdetail.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[LoginService]
})
export class LoginComponent implements OnInit {
  focus;
  focus1;

  ShowSuccessmessage : boolean;
  serverErrorMessage : boolean;
  getdata :{};
  cname :{};

  loginForm :FormGroup;
  message : string;
  Error =false;
  smobno;
  loginstatus$ = new BehaviorSubject<boolean>(null);
    //Username$ : Observable<string>;
    checkverification:boolean;

    //for otp
    closeResult = '';
    getotpform: FormGroup;
    getmobilenoform:FormGroup;
    @ViewChild('otpmodal') otpmodal;
    @ViewChild('otpmodalforgotpassword') otpmodalforgotpassword;
    verifyotp: any;
    mobileno: any;
    customerid;
  loginformmobileno: string;
  loginformpassword: string;
  Error1: any;
  forgotmobileno;
  changepassword: FormGroup;
  submitted: boolean;
  @ViewChild('changepass') changepass;
  count: any;
  pid: any;
  pqnt: any;
  constructor(private api1:ProductdetailService,private modalService: NgbModal,private userService:UserService,private loginservice:LoginService ,private  formbuilder:FormBuilder,private router:Router) { }

  get f (){return this.changepassword.controls}
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.checkverification =false;
    this.setFormState();
    this.setFormStateotpverification();
    this.setFormStategetmobileno();
    this.setFormStatechangepassword();
  }
  setFormState(): void{
    this.loginForm = this.formbuilder.group({
      mobileno : ['',[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      password : ['',[Validators.required]]
    })
  }

  onSubmit(){
    let login = this.loginForm.value;
    this.login(login);
   
  }
  
  refresh(): void {
      
    window.location.reload();
    
    //window.location.replace("/home");
  }
  itemsCart : any = [];
  login(login : User){
    this.loginservice.postuser(login).subscribe(
      res =>{ 
        this.loginstatus$.next(res["Data"])
        //debugger;
        let Message=res["Message"];
        let condition=res["IsSuccess"];
        let data=res["Data"];
        //console.log(res["Data"][0]["CustomerPhoneNo"]);
        console.log(login.mobileno)
        this.loginformmobileno =login.mobileno;
        this.loginformpassword = login.password;
        var success = res;
        //console.log(data['length']);
        //console.log(data.length);
        //console.log(res);
        //console.log(data);
        //console.log(data[0]);
        //console.log(data[0]['CustomerName'])
        if(data.length>0){
          this.loginForm.reset(); 

           
          
          let cname = data[0]['CustomerName'];
          let mobno = data[0]['CustomerPhoneNo']
          let customerid = data[0]['CustomerId'];
          //this.ShowSuccessmessage = true;
          //setTimeout(() => this.ShowSuccessmessage = false ,3000);
          //localStorage.setItem("Employee",JSON.stringify(success));
          /* this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.router.navigate(['']);
        }); */ 
        sessionStorage.setItem('CustomerName',cname);

        sessionStorage.setItem('CustomerId',customerid);

        sessionStorage.setItem('CustomerPhoneNo',mobno);
        console.log(mobno)

        let scname = sessionStorage.setItem('CustomerName',cname);
        this.loginservice.sessioncname.next(scname);


        this.loginservice.countCartDataByCustomerId(customerid).subscribe((data)=>{
          this.count =data["Data"];
         //alert(this.count)
         console.log("count"+this.count)
         /* let cartDataNull = localStorage.getItem('localcart');
         if(cartDataNull == null){
           let storeDataGet:any  =[];
           alert("null"+this.count)
           console.log("NULL"+this.count)
           console.log("Part Id:"+this.count.PartId)
           storeDataGet.push(this.count);
           localStorage.setItem('localcart',JSON.stringify(storeDataGet));
         } */ 

         this.cartNumberFunc();
         })

         /* this.loginservice.cartDetails(customerid).subscribe((data)=>{
          this.count =data["Data"];
        //  JSON.stringify(this.count);
          JSON.parse(JSON.stringify(this.count))

          
          this.pid =data["Data"][0]["PartId"];
          this.pqnt =data["Data"][0]["PartQty"]
          alert(this.pid)
         //alert(this.count)
         console.log("count"+this.count)
        // this.cartNumberFunc();
        alert("login")
        let cartDataNull = localStorage.getItem('localcart');
      if(cartDataNull == null){
        let storeDataGet:any  =[];
        alert("null"+this.count)
        console.log("NULL"+this.count)
        console.log("Part Id:"+this.count.PartId)
        storeDataGet.push(JSON.parse(JSON.stringify(this.count)));
        localStorage.setItem('localcart',JSON.stringify(storeDataGet));
      }
      else{
       // var partId=this.count.PartId;
        //var partQty = category.PartQty;
       // alert("else"+partId)
        let index:number = -1;
        let counter=0;
        this.itemsCart = JSON.parse(localStorage.getItem('localcart'));
        for(let i=0;i<this.itemsCart.length;i++){
          if(parseInt(this.pid) === parseInt(this.itemsCart[i].PartId)){
            this.itemsCart[i].PartQty = this.pqnt;
            index = i;
            break;

          }
        }
        if(index == -1){
          alert("h3")
          this.itemsCart.push(this.count);
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
        else{
          alert("h4")
          localStorage.setItem('localcart',JSON.stringify(this.itemsCart));
        }
      }

      this.cartNumberFunc();
     }) */
         

         
       // alert("home")
        this.router.navigate(['/home']).then(() => {
          window.location.reload();
        });
        //alert("refresh")
        //this.refresh();
        //alert("here")
        //this.refresh();
          //this.resetForm(form);

        }
        else{
          this.Error =res["IsSuccess"];
          //this.message =res["Message"];
          this.message = res["Message"];
          this.smobno = login.mobileno;
          

          if(res["Message"]== "You are not verified first verify with Otp Click Resend Otp Button"){
            this.checkverification = true;
            this.customerid =res["CustomerData"][0]["CustomerId"];
            this.mobileno =res["CustomerData"][0]["CustomerPhoneNo"];
          }
          else{
            this.checkverification = false;
          }

          //this.router.navigate(['/register'])
        
        //  this.serverErrorMessage= true;
        } 


          
          //console.log(res.valueOf.arguments.CustomerName);
          
      },);
  
  }
 
  /* onSubmit(form:NgForm){
    let login = form.value;
    this.loginservice.postuser(form.value).subscribe(
        res =>{ 
          var success = res;

          if(success){
            this.ShowSuccessmessage = true;
            setTimeout(() => this.ShowSuccessmessage = false ,3000);
            localStorage.setItem("Employee",JSON.stringify(success));
            //this.router.navigate(['/home']);
            //sessionStorage.setItem('CustomerPhoneNo','login.mobileno');
            this.resetForm(form);
          }
          else{

            this.serverErrorMessage= true;
          } 

  
            
            //console.log(res.valueOf.arguments.CustomerName);
            
        },);
    }
resetForm(form:NgForm){
        this.loginservice.selecteduser ={
            mobileno : '',
            password : ''
            
        };
        form.resetForm();
        this.serverErrorMessage=false;
} */





 //for otp 17/9/21
 setFormStateotpverification(): void{
  this.getotpform = this.formbuilder.group({
    otp : ['',[Validators.required,Validators.minLength(6)]], 
  })
}
onsubmitotp(){
  let otp =this.getotpform.value.otp;
 //alert(this.getotpform.value.otp);
 //alert(this.customerid)
 if(this.customerid != undefined){
  this.userService.verifyOTP(otp,this.customerid).subscribe((res)=>{
    this.verifyotp =res["Data"];
    if(this.verifyotp == 1){
      this.loginservice.loginotp(this.loginformmobileno,this.loginformpassword).subscribe(
        res =>{ 
          this.loginstatus$.next(res["Data"])
          //debugger;
          let Message=res["Message"];
          let condition=res["IsSuccess"];
          let data=res["Data"];
          //console.log(res["Data"][0]["CustomerPhoneNo"]);
         // console.log(login.mobileno)
        //  this.loginformmobileno =login.mobileno;
        //  this.loginformpassword = login.password;
        //  var success = res;
          //console.log(data['length']);
          //console.log(data.length);
          //console.log(res);
          //console.log(data);
          //console.log(data[0]);
          //console.log(data[0]['CustomerName'])
          if(data.length>0){
            this.loginForm.reset(); 
            
            let cname = data[0]['CustomerName'];
            let mobno = data[0]['CustomerPhoneNo']
            let customerid = data[0]['CustomerId'];
            //this.ShowSuccessmessage = true;
            //setTimeout(() => this.ShowSuccessmessage = false ,3000);
            //localStorage.setItem("Employee",JSON.stringify(success));
            /* this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
              this.router.navigate(['']);
          }); */ 
          sessionStorage.setItem('CustomerName',cname);
  
          sessionStorage.setItem('CustomerId',customerid);
  
          sessionStorage.setItem('CustomerPhoneNo',mobno);
          console.log(mobno)
  
          let scname = sessionStorage.setItem('CustomerName',cname);
          this.loginservice.sessioncname.next(scname);
         // alert("home")
          this.router.navigate(['/home']).then(() => {
            window.location.reload();
          });
          //alert("refresh")
          //this.refresh();
          //alert("here")
          //this.refresh();
            //this.resetForm(form);
          }
          else{
            this.Error =res["IsSuccess"];
            //this.message =res["Message"];
            this.message = res["Message"];
           // this.smobno = login.mobileno;
           this.customerid =res["CustomerData"][0]["CustomerId"];
           this.mobileno =res["CustomerData"][0]["CustomerPhoneNo"];
  
            if(res["Message"]== "You are not verified first verify with Otp Click Resend Otp Button"){
              this.checkverification = true;
              this.customerid =res["CustomerData"][0]["CustomerId"];
              this.mobileno =res["CustomerData"][0]["CustomerPhoneNo"];
            }
            else{
              this.checkverification = false;
            }
  
            
            //this.router.navigate(['/register'])
          
          //  this.serverErrorMessage= true;
          } 
  
  
            
            //console.log(res.valueOf.arguments.CustomerName);
            
        },);
    

            // Swal.fire({
            //   title: 'Thanks',
            //   text: 'Your OTP verification is Sucess',
            //   icon: 'success',
            //   //showCloseButton:true,
            //   showCloseButton:true,
            //  // showCancelButton: true,
            //   confirmButtonText: 'Login',
              
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //   //  alert("hello")
            //    /*  this.otpmodal.nativeElement.close();
            //      this.getotpform.disable();
            //    this.otpmodal.close();
            //    this.otpmodal.dismiss();
               
            //    this.otpmodal.hide(); */
            //   // this.reloadComponent();
            //     /* this.router.navigate(['/login']);
            //     this.refresh(); */

            //     this.router.navigate(['/login'])
            //     .then(() => {
            //          window.location.reload();
            //      });                    
               
              
            //   } else if (result.dismiss === Swal.DismissReason.close) {
                
            //   }
              
            // })
      
    }
    else{
      Swal.fire('Oops...', 'OTP verification failed Try Again :)', 'error')
    }
  })
 }
 else{
   //alert("hello")
   this.loginservice.verifyOTPForForgotPassword(otp,this.forgotmobileno).subscribe((res)=>{
    let data = res["Data"];
    if(data == 1){
     // alert("hello")
      this.open(this.changepass);
    }
   })

 }
  
}
openmodal(){
  this.open(this.otpmodal);
  this.resendotpapi();
}
resendotpapi(){
  
  this.userService.reSendOTPUsingSms(this.mobileno,this.customerid).subscribe((res)=>{
    let resend = res["Data"];
    if(resend == 1){
      Swal.fire({
        title: 'Thanks',
        text: 'OTP Send Sucessfully',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#FD8E01',
        
      }).then((result) => {
        if (result.isConfirmed) {
          //this.router.navigate(['/login']);
        
        } else if (result.dismiss === Swal.DismissReason.close) {
          
        }
        
      })

    }
  })
}

reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }


  //for forget password
  setFormStategetmobileno(): void{
    this.getmobilenoform = this.formbuilder.group({
      fpmobileno : ['',[Validators.required,Validators.minLength(10), Validators.maxLength(12)]], 
    })
  }
  onsubmitmobileno(){
    
    this.forgotmobileno =this.getmobilenoform.value.fpmobileno;
   // alert(mobileno)
    this.loginservice.sendOTPForForgotPassword(this.forgotmobileno).subscribe((res)=>{
      let data = res["Data"];
     // alert(res)
      if(data == 0){
        this.Error1 =res["IsSuccess"];
        this.message = res["Message"];
      }
      else if(data == 1){
       // alert("hii")
        this.open(this.otpmodalforgotpassword);
      }
      else if(data == 2){
            this.Error1 =res["IsSuccess"];
            this.message = res["Message"];
      }
    })
  }

  
  //for forget change password
  setFormStatechangepassword(): void{
    //console.log(this.defaddress)
    this.changepassword = this.formbuilder.group({
      newpassword : ['',[Validators.required,Validators.minLength(6)]],
      confirmnewpassword : ['',[Validators.required,]],
      
    },
    {
      validators :this.MustMatch('newpassword','confirmnewpassword')
    })
  }
  MustMatch(controlName:string,matchcontrolname:string){
    return(formGroup:FormGroup)=>{
      const control =formGroup.controls[controlName];
      const matchcontrol =formGroup.controls[matchcontrolname];
       if(matchcontrol.errors && !matchcontrol.errors.MustMatch){
      //  alert("1")
         return
       }
       if(control.value !== matchcontrol.value){
       // alert("2")
         matchcontrol.setErrors({MustMatch:true});
         return

       }
       else{
       //  alert("3")
         matchcontrol.setErrors(null);
         return

       }
    }
  }

  onclickchangepassword(){
    /* (formGroup:FormGroup)=>{
      const control =formGroup.controls[controlName];
      const matchcontrol =formGroup.controls[matchcontrolname];
    }; */

   /*  const control =changepassword.controls[controlName];
    const matchcontrol =changepassword.controls[matchcontrolname]; */
    this.submitted =true;

    // alert(this.changepassword.value.newpassword);
    if(this.changepassword.value.newpassword== this.changepassword.value.confirmnewpassword && this.changepassword.value.newpassword!=''){
      let password = this.changepassword.value.newpassword;

      //this.changepass(password);
      //return;
      this.loginservice.updateCustomerPassword(this.forgotmobileno,password).subscribe((res)=>{
        let data = res["Data"];
        if(data == 1){
          this.showsweetalert2();
        }
      })
    }
    else{
      let password = this.changepassword.value;
     // this.changepass(password);
    }
    
   // alert(password)
    //console.log(password);
    
    //console.log(this.changepassword)
   // this.changepassword.changepassword.va
   

  }
  /* changepass(password : Password){
    var customerid = sessionStorage.getItem('CustomerId');
    this.api.changepassword(customerid,password).subscribe(
      res =>{ 
            let data=res["Data"];   
            console.log(data)
            if(data){
              this.showsweetalert2();
            }
            else{
              this.Error =res["IsSuccess"];
              this.message =res["Message"];
              setTimeout(() => this.Error = false  ,6000);

              
            
            //  this.serverErrorMessage= true;
            } 
      });
    } */
    showsweetalert2(){
      Swal.fire({
        title: 'Thanks',
        text: 'Password Change Successfully',
        icon: 'success',
        //showCloseButton:true,
        showCloseButton:true,
       // showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FD8E01',
        
      }).then((result) => {
        if (result.isConfirmed) {
         // this.router.navigate(['/cart']);
        this.refresh();
        } else if (result.dismiss === Swal.DismissReason.close) {
          
        }
        
      })
    }

    

    cartNumber :number =0;
    cartNumberFunc(){
      var cartValue =JSON.parse(localStorage.getItem('localcart'));
      //this.cartNumber =cartValue.length;
      this.cartNumber =this.count;
    //  alert(this.count)
      
      this.api1.cartSubject.next(this.cartNumber);
      console.log(this.cartNumber);
    }


}
