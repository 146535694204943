import { HttpClient ,HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {User} from './login';
import { Customer } from '../signup/user';
import { observable ,Observable } from 'rxjs';
import { Global } from '../global-constant';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  /*selecteduser : User ={
    mobileno : '',
    password : ''
};*/
//baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
baseurl =Global.baseurl;
  sessioncname = new Subject<any>();
  constructor(private http:HttpClient) { }

  postuser(login :User) :Observable<Customer>{
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerPhoneNo", login.mobileno);
     userData.append("CustomerPassword", login.password);
     
    //sessionStorage.setItem('CustomerPhoneNo','login.mobileno');
     

    return this.http.post<Customer>(`${this.baseurl}/login_api`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  loginotp(CustomerPhoneNo:string,CustomerPassword:string) :Observable<Customer>{
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerPhoneNo", CustomerPhoneNo);
     userData.append("CustomerPassword", CustomerPassword);
     
    //sessionStorage.setItem('CustomerPhoneNo','login.mobileno');
     

    return this.http.post<Customer>(`${this.baseurl}/login_api`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  sendOTPForForgotPassword (MobileNo:string){
    return this.http.get(`${this.baseurl}/sendOTPForForgotPassword/`+MobileNo)
  }
  verifyOTPForForgotPassword(Otp :string,CustomerPhoneNo: string){
    var cid: any = new FormData();
    cid.append("Otp", Otp);
    cid.append("CustomerPhoneNo", CustomerPhoneNo);
    return this.http.post(`${this.baseurl}/verifyOTPForForgotPassword `,cid);
  }
  updateCustomerPassword(CustomerPhoneNo  :string,CustomerPassword:string){
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerPhoneNo", CustomerPhoneNo );
     userData.append("CustomerPassword", CustomerPassword);
     
     

    return this.http.post(`${this.baseurl}/updateCustomerPassword`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  countCartDataByCustomerId (CustomerId : string){

    var serachdata: any = new FormData();
    serachdata.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/countCartDataByCustomerId`,serachdata)
  }

  cartDetails(CustomerId: string){

    var addtocart: any = new FormData();
    addtocart.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCart`,addtocart);

  } 
}
