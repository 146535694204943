import { Component, OnInit } from '@angular/core';
import { BrandsService } from './brands.service';
import { Global } from '../global-constant';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  bannerurl = Global.bannerurl;
  brands :{};
  bparts : {};
  view:boolean;
  hide:boolean;
  constructor(private api:BrandsService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.api.getbrands().subscribe((data)=>{
      this.brands = data["Data"];
      this.view =true;
      this.hide =false;
  })

}
allbrands(){
 // this.router.navigate(['/brands']);
  this.api.allbrands().subscribe((data)=>{
    this.brands = data["Data"];
    this.hide =true;
    this.view =false;

})
}


  

}
