import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class BrandscatlogService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }


  getproductbybrand(BrandId : number){
  
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/part/brand/`+BrandId)
  }
}
