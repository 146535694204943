import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getcategorydata(){
    return this.http.get(`${this.baseurl}/CategoryPriority`,{responseType:"json"})
  }

  allCategorys(){
    return this.http.get(`${this.baseurl}/CategoryPriority/all`,{responseType:"json"})
  }
  
  //getcategorydatavehicle(modelno:string,mainModelNo:string){
    getcategorydatavehicle(modelno:string){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/CategoryPriority/all/`+modelno)
  }

  getcategorydataallvehicle(modelno:string){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/CategoryPriority/all/${modelno}/all` )
  }

}
