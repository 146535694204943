import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getbrands(){
    return this.http.get(`${this.baseurl}/Priority`,{responseType:"json"})
  }

  allbrands(){
    return this.http.get(`${this.baseurl}/Priority/all`,{responseType:"json"})
  }
  
}
