import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Customer} from './user';
import { observable } from 'rxjs';
import { Observable } from 'rxjs';
import { Global } from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {

 /* selecteduser : Customer ={
                name : '',
                companyname : '',
                mobileno : '',
                gstno : '',
                address : '',
                pincode : '',
                password : ''
  };*/
  //baseurl = "http://autoparts.sikho99.com/Project/Admin/Ajax";
  baseurl = Global.baseurl;
  constructor(private http:HttpClient) { }

  postuser(user :Customer):Observable<Customer>{
    //var subcategory: any = new FormData();
     var userData: any = new FormData();
     userData.append("CustomerName", user.name);
     userData.append("CustomerCompanyName", '');
     userData.append("CustomerPhoneNo", user.mobileno);
     userData.append("CustomerPassword", user.password);
     userData.append("CustomerGstNo", '');
     userData.append("CustomerAddress", '');
    userData.append("CustomerPincode", '');
     userData.append("CustomerImage", '');
    userData.append("CustomerShopImage", '');
    userData.append("CustomerEmailId",user.emailid);
     

    return this.http.post<Customer>(`${this.baseurl}/RegisterCustomer`, userData);
    // subcategory.append("CategoryId", CategoryId);
    // return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  getbannerdata(){
    return this.http.get(`${this.baseurl}/getBannerData`,{responseType:"json"})
  }
  getalloffers(){
    return this.http.get(`${this.baseurl}/get_all_data_api_final/offers`,{responseType:"json"})
  }
  verifyOTP(Otp :string,CustomerId: string){
    var cid: any = new FormData();
    cid.append("Otp", Otp);
    cid.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/verifyOTP `,cid);
  }

  reSendOTPUsingSms(MobileNo:string,CustomerId: string){
    var cid: any = new FormData();
    cid.append("MobileNo", MobileNo);
    cid.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/reSendOTPUsingSms `,cid);
  }
}